import React, { useState, useEffect } from 'react';
import config from '../config/config';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import PdfModal from '../common/PdfModal';



function BlogList() {
    const navigate = useNavigate();

    const { isLoggedIn } = useAuth();
    const { API_BASE_URL } = config;

    const [bookData, setBookData] = useState([]);
    const [selectedBookType, setSelectedBookType] = useState('Books for friends and students');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const itemsPerPage = 8;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/Home/Getbooks`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        bookType: selectedBookType,
                        searchTerm: searchTerm,
                    }),
                });

                const data = await response.json();

                const filteredBooks = data.filter((book) =>
                    book.booktype === selectedBookType || selectedBookType === 'all'
                ).filter((book) =>
                    book.booknameinenglish.toLowerCase().includes(searchTerm.toLowerCase())
                );

                setBookData(filteredBooks);
                setCurrentPage(1);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [selectedBookType, searchTerm, API_BASE_URL]);

    const handleViewPdfClick = (bookpdflink) => {
        setPdfUrl(bookpdflink);
        setShowModal(true);
       


        // sessionStorage.setItem('pdflink', bookpdflink)
        if (bookpdflink.includes('drive.google.com')) {

            // console.log("Pdflink", bookpdflink);
            // console.log(bookpdflink.includes('drive.google.com'));
            return window.open(bookpdflink, '_blank');

        }
        else {
            // navigate('/books/flip');


        }

        // Show the ad
        // setShowAd(true);
        // // Hide the ad after 10 seconds
        // setTimeout(() => {
        //     setShowAd(false);

        //     // navigate('/books/flip');
        //     // Redirect to PDF link
        //     // window.open(bookData.pdflink, '_blank');
        // }, 10000);
    };
    const handleCloseModal = ()=>{
        setShowModal(false);
    }

    const totalPages = Math.ceil(bookData.length / itemsPerPage);

    const scrollDownToSection = () => {
        const section = document.getElementById("booklist");
        section?.scrollIntoView({ behavior: "smooth" });
    };


    return (

        <section className='mt-8 mb-8'>
            <form
                action="/search" className="w-full px-4 max-w-4xl justify-center mx-auto">
                <div id='booklist' className="relative border border-blue-600/40 rounded-full">
                    <input type="text"
                        id="search"
                        placeholder="Search books by name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full h-12 p-4 rounded-full" />

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-teal-400 h-5 w-5 absolute top-3.5 right-3 fill-current">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </div>
            </form>



            <div

                className="md:flex md:gap-4 md:max-w-7xl md:justify-center md:mx-auto md:py-8 lg:flex lg:gap-4 lg:max-w-7xl lg:justify-center lg:mx-auto lg:py-8 xl:flex xl:gap-4 xl:max-w-7xl xl:justify-center xl:mx-auto xl:py-8 flex-none md:space-y-0 lg:space-y-0 xl:space-y-0 space-y-1 md:space-x-0 lg:space-x-0 xl:space-x-0 space-x-1">
                <button
                    className={`px-3 py-1 font-semibold text-white bg-blue-500 rounded ${selectedBookType === 'Books for friends and students' ? 'bg-blue-700' : ''
                        }`}
                    onClick={() => setSelectedBookType('Books for friends and students')}
                >
                    Books for friends and students
                </button>



                {isLoggedIn && (
                    <button
                        className={`px-3 py-1 font-semibold text-white bg-blue-500 rounded ${selectedBookType === 'Books for family ' ? 'bg-blue-700' : ''}`}
                        onClick={() => setSelectedBookType('Books for family ')}
                    >
                        Books for family
                    </button>
                )}

                <button
                    className={`px-3 py-1 font-semibold text-white bg-blue-500 rounded ${selectedBookType === 'Literary calendars and magazine' ? 'bg-blue-700' : ''
                        }`}
                    onClick={() => setSelectedBookType('Literary calendars and magazine')}
                >
                    Literary calendars and magazine
                </button>

            </div>

            <div className='max-w-7xl mx-auto py-5'><p className='text-lg font-semibold bg-yellow-400 py-2 px-3 w-fit mb-2'><h2>Book List</h2></p> <hr className='border-b-2 border-gray-200' /></div>

            <div className="text-left md:flex-row max-w-full md:px-16 lg:px-16 justify-center xl:px-20 px-[2px] mx-auto md:mt-5 mt-10 items-center z-0">

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 md:px-5 lg:px-12 xl:px-12 px-0 gap-8 md:space-y-0 lg:space-y-0 xl:space-y-0 space-y-2 pb-2 md:gap-y-8 lg:gap-y-8 xl:gap-y-8 gap-y-5">
                    {bookData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((book) => (

                        <div
                            key={book.id}
                            className="relative flex flex-col text-gray-800 bg-white md:w-64 lg:w-72 xl:w-72 w-full border border-gray-200 p-1 rounded-md space-y-3 hover:scale-105 hover:transition hover:ease-out cursor-pointer">
                            <div className="relative h-full text-white overflow-hidden shadow-lg bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40 flex items-center mx-auto bg-white">
                                <a href={book.pdflink} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={book.image}
                                        alt={book.booknameinenglish}
                                        layout="fill"
                                        objectFit="cover"
                                        className="bg-blue-200 h-54 mx-auto"
                                    />
                                </a>
                            </div>

                            <div className='flex items-center justify-between px-1 cursor-pointer'>
                                <h5 className="block font-sans md:text-xl lg:text-xl xl:text-xl text-xl font-bold leading-snug tracking-normal text-blue-gray-900">
                                    {book.booknameinenglish}
                                </h5>



                            </div>
                            <p className="block text-sm px-2 font-normal leading-5">
                                {book.description}
                            </p>

                            <div class="flex items-center justify-between px-2 pb-1">

                                {/* <p onClick={() => handleViewPdfClick(book.pdflink)} rel="noopener noreferrer"><button class="flex items-center rounded-md bg-[#e4002b] px-3 py-1.5 text-center text-sm font-medium text-gray-200 hover:bg-yellow-400 focus:outline-none focus:ring-4 focus:ring-blue-300" >

                                    Read Book (Free)</button></p> */}
                                    <p>
                                    <a
                      href={book.pdflink}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                      //   onClick={(e) => {
                      //     e.preventDefault(); // Prevent default navigation if you also want to handle the click event
                      //     handleViewPdfClick(book.pdflink);
                      //   }}
                    >
                      <button class="flex items-center rounded-md bg-[#e4002b] px-3 py-1.5 text-center text-sm font-medium text-gray-200 hover:bg-yellow-400 focus:outline-none focus:ring-4 focus:ring-blue-300">
                        Download For Free
                      </button>
                      
                    </a>
                                    </p>

                            </div>


                        </div>
                    ))}

                </div>
            </div>

            <div className="flex justify-center mt-8">
                <button
                    onClick={() => {
                        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
                        scrollDownToSection();
                    }}
                    disabled={currentPage === 1}
                    className="px-3 py-2 mr-2 font-semibold text-gray-700 bg-gray-200 rounded"
                >
                    Previous
                </button>
                <span className="px-3 py-2 font-semibold text-gray-700">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={() => {
                        setCurrentPage((prevPage) => prevPage + 1);
                        scrollDownToSection();
                    }}
                    disabled={currentPage * itemsPerPage >= bookData.length}
                    className="px-3 py-2 ml-2 font-semibold text-white bg-blue-500 rounded"
                >
                    Next
                </button>
            </div>
            {showModal &&  pdfUrl &&  <PdfModal value={showModal} pdf_link={pdfUrl}  onClose={handleCloseModal}/>}

        </section>
    );
}

export default BlogList;
