// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice'; // example slice
import bookReducer from '../ReduxSlice/BookSlice'

export const store = configureStore({
  reducer: {
    books: bookReducer, // Add your slices here
  },
  middleware:(getDefaultMiddleware) =>
    getDefaultMiddleware().concat(),
  
});

export default store;
