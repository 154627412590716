import React from 'react'
import SpecialHero from '../components/SpecialHero'
import SpecialBook from '../components/SpecialBook'

function SpecialBooksPage() {
    return (
        <div>
            <SpecialHero />
            <SpecialBook />
        </div>
    )
}

export default SpecialBooksPage