import React from 'react'
import Video from '../components/Video';

function VideoPage() {
    return (
        <div>
            <Video />
        </div>
    )
}

export default VideoPage;