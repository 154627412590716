import React from 'react'
import TrackOrder from '../components/TrackOrder'

function TrackPage() {
    return (
        <div>
            <TrackOrder />
        </div>
    )
}

export default TrackPage