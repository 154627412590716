import React, { useEffect, useState } from 'react'
import { BookOpen, Star, Award, Download, Search, Banknote, ShoppingCart, CreditCard, Info, MailIcon, } from 'lucide-react';
import { selectCartData, selectTotalBookPrice } from '../../ReduxSlice/BookSlice';
import { useSelector } from 'react-redux';


const Cart = ({ openModal ,paymentMode}) => {

   const BookCart = useSelector(selectCartData);
      const totalQuanity = BookCart.reduce((sum,item)=> sum+item.quantity,0)
      // console.log("totalQuanity",totalQuanity)
  // const [totalPrice, setTotalPrice] = useState(null)
      const  totalPrice = useSelector(selectTotalBookPrice);
  

  const cartItems = useSelector(selectCartData);

  // const calculateSubtotal = () => {
  //   return setTotalPrice(cartItems.reduce((total, item) => total + item.price * item.quantity, 0));
  // };
  useEffect(() => {

    // calculateSubtotal();


  }, [cartItems])

  return (
    <div className="sticky top-0 left-0  right-0 bg-gradient-to-b from-gray-900 to-gray-800 shadow-lg border-t py-4 md:p-4 px-2">
      <div className="container ">
        <div className="flex justify-between items-center">
          <button
            onClick={() => {openModal(true); paymentMode("cart_item");}}
            className="flex items-center hover:text-blue-600 transition"
          >
             
            <ShoppingCart className="md:mr-2 relative" />
            <span className="font-semibold">
              {/* Total: ${totalAmount.toFixed(2)} ({cart.reduce((sum, item) => sum + item.quantity, 0)} items) */}
              Total : {totalPrice}
            </span>
            <div className='absolute top-2 md:top-3 bg-red-600 text-white px-1 rounded-full left-1 text-sm'>{totalQuanity}</div>
          </button>
          <div className="flex  gap-2 md:gap-4">
  {/* Online Payment Button */}
  <button
    onClick={() => {
      openModal(true);
      paymentMode("online");
    }}
    className="flex items-center bg-green-600 text-white px-1 py-1 md:px-4 md:py-2 rounded-lg hover:bg-green-700 transition"
  >
    <CreditCard className="mr-2 w-5 h-5" />
    <span className="text-sm md:text-base">Online Pay</span>
  </button>

  {/* Cash Payment Button */}
  <button
    onClick={() => {
      openModal(true);
      paymentMode("cashPay");
    }}
    className="flex items-center bg-yellow-600 text-white px-1 py-1 md:px-4 md:py-2 rounded-lg hover:bg-yellow-700 transition"
  >
    <Banknote className="mr-2 w-5 h-5" />
    <span className="text-sm md:text-base">
      Cash Pay<span className="hidden md:inline">ment</span>
    </span>
  </button>

  <button
    onClick={() => {
      openModal(true);
      paymentMode("paybyMail");
    }}
    className="flex items-center bg-yellow-600 text-white px-1 py-1 md:px-4 md:py-2 rounded-lg hover:bg-yellow-700 transition"
  >
    <MailIcon className="mr-2 w-4 h-4" />
    <span className="text-sm md:text-base">
      Pay by Mail
    </span>
  </button>
</div>

        </div>
      </div>
    </div>
  )
}

export default Cart
