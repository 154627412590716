import { motion} from 'framer-motion'
import React from 'react'

const CardLoaderSkelton = () => {
  return (
   // Skeleton Loader
   <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
   {[...Array(4)].map((_, index) => (
     <div key={index} className="flex justify-center">
       <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 0.5 }}
         className="rounded-2xl shadow-xl overflow-hidden max-w-[28rem] w-full sm:w-[500px] bg-white p-6"
       >
         <div className="relative bg-gradient-to-br from-gray-200 to-gray-300 p-6 h-96 animate-pulse rounded-lg" />
         
         <div className="mt-4">
           <div className="h-6 bg-gray-300 rounded w-3/4 animate-pulse" />
           <div className="h-4 bg-gray-300 rounded w-1/2 mt-2 animate-pulse" />
         </div>
         
         <div className="mt-4 flex items-center justify-between">
           <div className="h-6 bg-gray-300 rounded w-1/3 animate-pulse" />
           <div className="h-6 bg-gray-300 rounded w-1/4 animate-pulse" />
         </div>

         <div className="mt-4 h-10 bg-gray-300 rounded animate-pulse" />
       </motion.div>
     </div>
   ))}
 </div>
  )
}

export default CardLoaderSkelton