import React from 'react'
import BlogList from '../components/BlogList'
import BlogHero from '../components/BlogHero'

function BlogPage() {
    return (
        <div>

            <BlogHero />
            <BlogList />
        </div>
    )
}

export default BlogPage