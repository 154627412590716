import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config/config';

function DASHBOARD_USER_PROFILE() {

    const { API_BASE_URL } = config;

    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${API_BASE_URL}/auth/Changepswd`, {
                email,
                oldpassword: oldPassword,
                password: newPassword,
            });


            toast.success(response.data);
        } catch (error) {

            toast.error('Error changing password.');
            console.error(error);
        }
    };


    return (
        <div className="mb-6">
            <h4 className="text-lg text-gray-800 font-bold pb-2 mb-4 border-b-2">Profile</h4>
            <div class="flex flex-col px-6 py-8 md:h-auto lg:py-0 border">
                <div class="w-full bg-white rounded-lg md:mt-0 xl:p-0 ">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                            Change password to your account
                        </h1>
                        <form class="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div className='md:flex md:gap-4 lg:flex lg:gap-4 xl:flex xl:gap-4 flex-none'>
                                <div className='w-full'>
                                    <label class="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                                    <input type="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required />
                                </div>
                                <div className='w-full'>
                                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
                                    <input type="password"

                                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        required />
                                </div>

                                <div className='w-full'>
                                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">New Password</label>
                                    <input type="password"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required />
                                </div>
                            </div>

                            <button type="submit" class="w-fit text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Change Password</button>
                        </form>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    )
}

export default DASHBOARD_USER_PROFILE