import React from 'react'
import Header from '../components/Header'

function PrivacyPolicy() {
    return (
        <div>
            <Header />
            <div className="bg-white text-gray-900 p-6 md:p-12 lg:p-20 -mt-12 font-sans text-justify bg-slate-100 ">
                <h1 className="text-3xl font-semibold mb-4 -mt-9">PRIVACY POLICY
                </h1>
                <p className="text-gray-700 mb-4">
                    At Hema Aunty (Hem Aunty Publications), we respect your privacy. We want to ensure that you get the information, content, and experiences that matter most to you. We are committed to protecting the privacy of our members, customers, volunteers, and other supporters.
                </p>
                <p className="text-gray-700 mb-4">
                    This document is a written agreement and an electronic record and valid and enforceable electronic agreement/contract under the <strong>Information Technology Act, 2000 (as applicable in the Republic of India) </strong>and rules thereunder and the amended provisions pertaining to electronic records in various statutes under applicable Indian laws. This electronic record is generated by a computer system and does not require any physical or digital signatures. Your usage of the website shall be your deemed acceptance of this Privacy Policy and all the modifications and updates thereto.
                </p>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
                    <p className="text-gray-700">
                        We do not publish, sell or rent your personal information to third parties for their marketing purposes without your consent, which includes your acceptance of this Privacy Policy. Please read this privacy policy carefully, to learn more about the ways in which we use and protect your personal information. If you do not agree with the contents of this policy, please do not use or access our website.
                    </p>
                    <p className="text-gray-700">
                        This Privacy Policy covers the information, Hema Aunty <strong>(Hem Aunty Publications) </strong>(“Platform” and/or “We”) collects from the user(s) (“User(s)” and/or “You”) of whether or not registered with <strong>www.hemaunty.org (“Website” including any successor website) and includes the Services –</strong> the term as defined in the Terms and Conditions). This Privacy Policy should be read in conjunction and together with the Terms and Conditions of the website.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Scope</h2>
                    <p className="text-gray-700">
                        This Privacy Policy applies to all personal data processed by full-time and part-time employees, volunteers when acting on behalf of Hema Aunty <strong>(Hem Aunty Publications), contractors and partners doing business on behalf of Hema Aunty (Hem Aunty Publications), </strong>as well as all legal entities, all operating locations in all countries, and all business processes conducted by Hema Aunty (Hem Aunty Publications).
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">What Information We Collect</h2>
                    <p className="text-gray-700">
                        During your registration or usage of the website, you may provide and/or we may access, capture or store various sensitive personal information, personal information or general information or data with respect to your activities or usage on the website. We may install cookies or other similar data collection software in your computer system for such purposes and you hereby consent to the same.
                    </p>
                    <p className="text-gray-700">
                        For donors and buyers of our products, information we collect may include <strong>your email, full name, address, mobile number, PAN number, user identification (for accessing the e-store), password (associated with the aforementioned user identification), and billing information</strong> which we are lawfully required to collect in order to do a business transaction with you. Your email address and billing/shipping address are collected so we can send you the requested product/communications.
                    </p>
                    <p className="text-gray-700">
                        For subscribers of our newsletter, we only collect your first name and your email address after you have successfully subscribed to our list and confirmed your subscription to make sure it’s really you.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Correct, Current and Updated Information</h2>
                    <p className="text-gray-700">
                        You represent to us that the information or data you provide to us on the website from time to time is and shall be correct, current and updated and you have all the rights, permissions and consents to provide such information or data to us. Your providing the information or data to us and our <strong>consequent storage, collection, usage, transfer, access or processing </strong>of the same shall not be in violation of any third-party agreement, laws, charter documents, judgments, orders and decrees.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Information for Which We Are Not Responsible</h2>
                    <p className="text-gray-700">
                        While using the website, you may provide sensitive personal information, or other general information about yourself or in connection with yourself to third parties. The<strong> website may have webpages, cookies, files, software, data or other information or communication links </strong>that are originated, created or posted by third parties (including advertisers, content or other partners or users) that can or has the capabilities of collecting, storing or using your data/information. We are not responsible for such data or information collected, stored or used by third parties even if such third parties use the website to collect, store or use such data or information.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">How We Protect Your Information</h2>
                    <p className="text-gray-700">
                        We use reasonable security measures, at the minimum, those mandated under the Information <strong>Technology Act, 2000,</strong> as amended and read with Information<strong> Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011,</strong> to safeguard and protect your data and information. However, you may note that we may collect, store and process your information or data in computer resources, computer systems or computer networks which may not be owned or controlled by us and may be situated outside the Republic of India. You acknowledge that governmental or law enforcement authorities in the countries where your data or information is stored may have the right to decrypt, collect, monitor or access your data or information whose actions are completely out of our control. We don’t take any responsibilities for such actions.
                    </p>
                    <p className="text-gray-700">
                        We utilize up-to-date security measures and policies for the protection of our website and to help avoid unauthorized access or corruption. Unfortunately, it is not possible to guarantee total security of information held and data and information transmitted via the internet may not be secure, may be routed incorrectly, and/or is susceptible to interception by unauthorized parties. Therefore, the confidentiality and security of data and information provided by electronic means cannot be guaranteed and personal or sensitive information should not be provided unless you can satisfy yourself that you are using tried and tested encryption or secure communications software. However, every reasonable effort will be made to keep our website secure and to avoid unauthorized access or corruption. Use of the internet is not wholly secure and data and information provided via the internet can end up in the hands of unauthorized third parties despite best efforts to ensure security at all times. In providing any data or information to us by electronic means, you are agreeing to the transfer, storing and processing of information which could unknowingly be intercepted by an unauthorized person.
                    </p>
                    <p className="text-gray-700">
                        Our website may, from time to time, contain links to and from other websites. If you follow a link to any of those websites, please note that you do so entirely at your own sole risk and responsibility. We do not accept any responsibility or liability for, nor do we make any representation in respect of, or endorse the content or material of, the external websites in any links provided. We would strongly urge you to exercise caution before you submit any personal data to or information to any of those websites.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Use of Information</h2>
                    <p className="text-gray-700">
                        The data we collect is only used for the following:
                    </p>
                    <ul className="list-disc list-inside text-gray-700">
                        <li><strong>To process transactions:</strong> We use personal information such as name, physical address, telephone number, email address, and company/institution to engage in interactions with you, including contacting you about your order, donation, subscription, event participation, or membership. We use financial/credit card and payment information to process your order and take the utmost care to safeguard the information and do not share it with any third parties.</li>
                        <li><strong>To provide support or other services:</strong> We may use your personal information to provide you with support or other services that you have ordered or requested. We may also use your personal information to respond directly to your requests for information, including registrations for newsletters or other specific requests, or pass your contact information to the appropriate Hema Aunty contact for further follow-up related to your interests.</li>
                        <li><strong>To provide information based on your needs and respond to your requests:</strong> We may use your personal information to provide you with notices of new product releases and service developments.</li>
                        <li><strong>To administer products: </strong>We may contact you if you purchase products to confirm certain information about your order (for example, that you did not experience problems in the download process). We may also use this information to confirm compliance with licensing and other Terms and Conditions and may share it with your company/institution, as applicable.</li>
                        <li><strong>To select content, improve quality, and facilitate use of the website: </strong>We may use your personal information, including the information gathered as a result of site navigation and electronic protocols and cookies (including third-party cookies), to help create and personalize website content, improve website quality, evaluate page response rates, conduct usability testing, and facilitate your use of the websites (for example, to facilitate navigation and the login process, avoid duplicate data entry, enhance security, keep track of shopping cart additions, and preserve order information between sessions).</li>
                        <li><strong>To assess usage of Hema Aunty’s (Hem Aunty Publications)products and services:</strong> We may track your usage of its products and services to determine your level of usage, and those usage statistics may be made available to Hema Aunty’s (Hem Aunty Publications)content licensors and your company/institution, as and if applicable.</li>
                        <li><strong>To protect Hema Aunty (Hem Aunty Publications)content and services:</strong> We may use your information to prevent potentially illegal activities and to enforce our Terms and Conditions. We also use a variety of technological systems to detect and address anomalous activity and to screen content to prevent abuse, such as spam. These efforts may, on occasion, result in a temporary or permanent suspension or termination of some functions for some users.</li>

                        <li><strong>To get feedback or input from you:</strong> In order to deliver products and services of the most interest to our registered users and customers, from time to time, we may ask members, customers, volunteers, and website visitors to provide us input and feedback (for example through surveys, usability studies, focus groups).</li>
                        <li><strong>To engage with third parties:</strong> We may use third parties that we refer to as internal service providers to facilitate or outsource one or more aspects of service operations that we provide to you on the website (e.g., search technology, discussion boards, technical service providers, affiliate) and therefore, we may provide some of your information or data directly to these internal service providers. These internal service providers are subject to confidentiality agreements with us and other legal restrictions that prohibit their use of the information we provide them for any other purpose except to facilitate the specific website-related operations. In some instances, the internal service provider may collect information directly from you (such as for website surveys or for co-branded services). In these cases, you will be notified of the involvement of the internal service provider, and all additional information you provide them and their additional uses will be strictly up to you. If you provide additional information to an internal service provider directly, then their use of your personal information is governed by their applicable privacy policy, if any.</li>
                    </ul>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Retention and Withdrawal</h2>
                    <p className="text-gray-700">
                        You can control the information we have about you and how we use it in several ways.
                    </p>
                    <p className="text-gray-700">
                        For customers/donors: We retain your personal information only for contractual and legal reasons (for example, we are legally required to keep certain financial information for at least 7 years for tax purposes).
                    </p>
                    <p className="text-gray-700">
                        For newsletter subscribers: We retain your personal information until you choose to unsubscribe from our newsletters. You can unsubscribe at any time by following the unsubscribe link in the email, or by contacting us directly.
                    </p>
                    <p className="text-gray-700">
                        For other interactions: We retain personal information only as long as necessary for the purposes described in this Privacy Policy, or as required by law. When we no longer need to retain your personal information, we securely delete or destroy it.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Your Rights</h2>
                    <p className="text-gray-700">
                        You have the right to request access to the personal data we hold about you, request correction of any inaccuracies, object to the processing of your personal data, or request its deletion. If you wish to exercise any of these rights, please contact us at the details provided below. We will respond to your request in accordance with applicable laws.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Changes to this Privacy Policy</h2>
                    <p className="text-gray-700">
                        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Policy changes if and where this is required by applicable data protection laws. We encourage you to periodically review this page for the latest information on our privacy practices.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
                    <p className="text-gray-700">
                        If you have any questions or concerns about this Privacy Policy or our data practices, or if you wish to exercise your rights as described above, please contact us at:
                    </p>
                    <address className="text-gray-700 not-italic">
                        Hema Aunty <br />
                        <strong>Email: hemprakashan@gmail.com <br />
                        Phone: 9958410093 <br />
                        Physical Address: 18 Chitra Vihar, Delhi 110092</strong>
                    </address>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Consent</h2>
                    <p className="text-gray-700">
                        By using our website, you consent to the collection, use, and storage of your information by us in the manner described in this Privacy Policy. If you do not agree with this policy, please do not use our website.
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Governing Law and Jurisdiction</h2>
                    <p className="text-gray-700">
                        This Privacy Policy and your use of the website shall be governed in all respects by the laws of India. Any dispute arising under this Privacy Policy shall be resolved exclusively by the competent courts of [Your Jurisdiction].
                    </p>
                </section>

                <section className="mb-6 -mt-3">
                    <h2 className="text-2xl font-semibold mb-2">Disclaimer</h2>
                    <p className="text-gray-700">
                        We shall not be liable for any loss, damage (whether direct, indirect, consequential or incidental) or harm caused to you due to unauthorized access or misuse of your personal information, and you agree to hold us harmless and indemnify us from any claims or damages arising out of such unauthorized access or misuse.
                    </p>
                </section>
            </div>
        </div>
    )
}

export default PrivacyPolicy