import React from 'react'
import BooksContent from '../components/BooksContent'

const BookDetails = () => {
    return (
        <div>
            <BooksContent />
        </div>
    )
}

export default BookDetails