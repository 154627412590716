import React from 'react';
import slider1 from '../assets/images/cover_webpage4.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import slider2 from "../assets/images/slider2.webp";
import slider3 from "../assets/images/slider3.webp"
// import BannerNav from './BannerNav';
import slider4 from "../assets/images/slider1.webp"
import slider5 from "../assets/images/slider4.webp"


const Hero = () => {
    return (
        <div>
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}>
                <div style={{ position: "relative" }}>
                    <img
                        className="md:h-[550px] lg:h-[550px] xl:h-[550px] h-[200px] "
                        src={slider3}
                        alt="book-banner-1"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",

                        }}
                    >

                    </div>
                </div>
                <div style={{ position: "relative" }}>
                    <img
                        className="md:h-[550px] lg:h-[550px] xl:h-[550px] h-[200px] "
                        src={slider4}
                        alt="book-banner-1"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",

                        }}
                    >

                    </div>
                </div>
                <div style={{ position: "relative" }}>
                    <img
                        className="md:h-[550px] lg:h-[550px] xl:h-[550px] h-[200px] "
                        src={slider5}
                        alt="book-banner-1"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",

                        }}
                    >

                    </div>
                </div>
                <div style={{ position: "relative" }}>
                    <img
                        className="md:h-[550px] lg:h-[550px] xl:h-[550px] h-[200px] brightness-75 "
                        src={slider1}
                        alt="book-banner-1"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                            className="max-w-7xl mx-auto md:space-y-8 lg:space-y-8 xl:space-y-8 space-y-6 md:py-24 lg:py-24 xl:py-24 py-4 md:px-0 lg:px-0 xl:px-0 px-4 flex items-center"
                        >

                        </div>
                    </div>
                </div>

                <div style={{ position: "relative" }}>
                    <img
                        className="md:h-[550px] lg:h-[550px] xl:h-[550px] h-[200px]  "
                        src={slider2}
                        alt="book-banner-1"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",

                        }}
                    >

                    </div>
                </div>


            </Carousel>
        </div>
    )
}

export default Hero;