import React, { useEffect, useState } from 'react';
import config from '../config/config';
import DASHBOARD_ORDERDETAILS from './DASHBOARD_ORDERDETAILS';

function DASHBOARD_ORDERS() {
    const { API_BASE_URL } = config;
    const [orderDetails, setOrderDetails] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/Home/getorderdetails`, {
                    method: 'POST',
                    headers: {
                        'Token': '28a076d9-b5fd-11ee-871f-74867ad6e338',
                        'UniqueId': '35',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({}),
                });

                if (response.ok) {
                    let data = await response.json();

                  //  data.sort((a, b) => a.orderId - b.orderId);

                 //   data.reverse();
                    setOrderDetails(data);
                } else {
                    console.error('Error fetching order details');
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
            }
        };

        fetchOrderDetails();
    }, [API_BASE_URL]);

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
    };

    const openOrderDetailsModal = (order) => {
        console.log('Order details modal opened with order:', order);
        setSelectedOrder(order);
    };

    const closeOrderDetailsModal = () => {
        setSelectedOrder(null);
    };

    return (
        <div className="mb-6 bg-gray-100 md:p-4 lg:p-4 xl:p-4 2xl:p-4 p-1">
            <h4 className="text-lg text-gray-800 font-bold pb-2 mb-4 border-b-2">Orders</h4>

            <div className="overflow-hidden max-w-7xl w-full bg-white md:p-2 lg:p-2 xl:p-2 2xl:p-2 p-1">
                <table className="w-full  text-left text-sm text-gray-500">
                    <thead className="">
                        <tr>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Order ID</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Name</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Total</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Order Date</th>
                            <th scope="col" className="px-6 py-4 font-medium text-gray-900">Payment Date</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                        {orderDetails.map((order) => (
                            <tr key={order.orderId} className="hover:bg-gray-50">
                                <td className="px-6 py-4">{order.orderId}</td>
                                <td className="px-6 py-4">{order.name}</td>
                                <td className="px-6 py-4">{order.total}</td>
                                <td className="px-6 py-4">{formatDateTime(order.orderDate)}</td>
                                <td className="px-6 py-4">{formatDateTime(order.paymentDate)}</td>
                                <td className="px-6 py-2">
                                    <button onClick={() => openOrderDetailsModal(order)} className='bg-yellow-400 font-semibold px-3 py-1 rounded-md'>
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedOrder && (
                <DASHBOARD_ORDERDETAILS order={selectedOrder} onClose={closeOrderDetailsModal} />
            )}
        </div>
    );
}

export default DASHBOARD_ORDERS;
