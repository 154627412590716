import React, { useState,useEffect } from 'react';
import { Star, Info, Download, BookOpen, Percent, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector,useDispatch } from 'react-redux';
import { selectCartData ,removeFromCarts,updateCartQuantity, totalPrice, selectTotalBookPrice, selecteDiscount} from '../../ReduxSlice/BookSlice';
import { useNavigate } from 'react-router-dom';

const CartItem = () => {

    const dispatch = useDispatch();
    const cartItems = useSelector(selectCartData); 
    console.log("cartItems",cartItems);
     // Use Redux state instead of local state
    const  total = useSelector(selectTotalBookPrice);
    const [subtotal, setSubtotal] = useState(0);
    // const [total, setTotal] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const navigate = useNavigate();
    const discount = useSelector(selecteDiscount);

    const [isDiscount, setIsDiscounts] = useState(discount);
    const [discountCode, setDiscountCode] = useState('');
    const [isCodeValid, setIsCodeValid] = useState(false);
  
   
  
    // Calculate subtotal based on cart items
    const calculateSubtotal = () => {
      return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };
  
    // Calculate shipping charges
    const calculateShippingCharges = (subtotalValue) => {
      if (subtotalValue >= 2000) {
        return 0;
      } else {
        return subtotalValue * 0.2;
      }
    };
  
    // Calculate total cost including shipping
    const calculateTotal = (percantage) => {
      const subtotalValue = calculateSubtotal();
      const discountPrice = subtotalValue-(subtotalValue * (percantage/100));
      return discountPrice
    };
  
    // Whenever the cart items change, recalculate subtotal and total
    useEffect(() => {
      const subtotalValue = calculateSubtotal();
      setSubtotal(subtotalValue);
      const shippingCharges = calculateShippingCharges(subtotalValue);
      setDeliveryCharge(shippingCharges);
      // setTotal(subtotalValue + shippingCharges);
    }, [cartItems]);  // Dependency on Redux cartItems
  
    // Handle removing an item from the cart via Redux action
    const removeFromCart = (bookId) => {
      dispatch(removeFromCarts(bookId));
    };
  

  
    const updateQuantity = (bookId, newQuantity) => {
      // setCartItems(prevCartItems => {
      //   const updatedCart = prevCartItems.map(item =>
      //     item.id === bookId ? { ...item, quantity: newQuantity >= 0 ? newQuantity : 0 } : item
      //   );
  
      //   localStorage.setItem('cart', JSON.stringify(updatedCart));
      //   return updatedCart;
      // });
      dispatch(updateCartQuantity({ bookId, newQuantity }));
  
    };
   
     
    
    
      const handleDiscountCode = (e) => {
        setIsDiscounts(e.target.value)
        
      };
      const setIsDiscount = () =>{
        // const total = calculateTotal(isDiscount);
        dispatch(totalPrice(isDiscount));
        // setTotal(total);


      }
    
  return (

     //Cart Items with Scroll 
     <div className="max-w-4xl mx-auto">
     <div className="bg-white shadow">
       <div className="px-4 py-6 sm:px-8 sm:py-10">
         {/* Scrollable Cart Items */}
         <div className="max-h-[200px] overflow-y-auto ">
           <ul className="-my-8">
             {cartItems.map((item, index) => (
               <li key={index} className="flex flex-col space-y-3 py-6 text-left sm:flex-row sm:space-x-5 sm:space-y-0 mt-6">
                 <img className="w-20 h-20 object-cover mr-4" src={item.image} alt={item.name} />

                 <div className="relative flex flex-1 flex-col justify-between">
                   <p className="text-base font-semibold text-gray-900">{item.name}</p>

                   <div className="mt-4 flex items-end justify-between">
                     <p className="text-base font-semibold text-gray-900">
                       Rs.{item.price * item.quantity}
                     </p>

                     {/* Quantity Controls */}
                     <div className="flex h-8 text-gray-600">
                       <button onClick={() => updateQuantity(item.id, item.quantity - 1)} className="rounded-l-md bg-gray-200 px-4 hover:bg-black hover:text-white">
                         -
                       </button>
                       <div className="bg-gray-100 px-4">{item.quantity}</div>
                       <button onClick={() => updateQuantity(item.id, item.quantity + 1)} className="rounded-r-md bg-gray-200 px-4 hover:bg-black hover:text-white">
                         +
                       </button>
                     </div>
                   </div>

                   {/* Remove Button */}
                   <button onClick={() => removeFromCart(item.id)} className="absolute top-0 right-0 text-gray-500 hover:text-gray-900">
                     ✖
                   </button>
                 </div>
               </li>
             ))}
           </ul>
         </div>
       </div>

       {/* Discount Code Input */}
                         <motion.div
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           className="mb-2"
                         >
                           <div className="flex gap-2">
                             <div className="flex-1">
                               <input
                                 type="number"
                                 placeholder="Enter discount in percentage"
                                //  value={discountCode}
                                 onChange={handleDiscountCode}
                                 className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors text-gray-800"
                               />
                             </div>
                             <motion.button
                               whileHover={{ scale: 1.05 }}
                               whileTap={{ scale: 0.95 }}
                               onClick={setIsDiscount}
                               
                               className={`px-4 py-2 rounded-lg font-semibold
                                   bg-indigo-600 text-white hover:bg-indigo-700'
                                    
                                 `}
                             >
                               Apply
                             </motion.button>
                           </div>
                           {discountCode && !isCodeValid && (
                             <p className="text-red-500 text-sm mt-1">Invalid code. Try "MATH2024"</p>
                           )}
                           {isCodeValid && isDiscount && (
                             <p className="text-green-600 text-sm mt-1">Extra 15% discount applied!</p>
                           )}
                         </motion.div>

       {/* Subtotal & Shipping */}
       <div className="mt-6 border-t border-b py-2">
         <div className="flex items-center justify-between">
           <p className="text-sm text-gray-400">Subtotal</p>
           <p className="text-sm font-medium text-gray-900">Rs.{subtotal}.00</p>
         </div>
         <div className="flex items-center justify-between">
           <p className="text-sm text-gray-400">Discount</p>
           <p className="text-sm font-medium text-gray-900">{isDiscount}%</p>
         </div>
       </div>

       {/* Total Price */}
       <div className="mt-6 flex items-center justify-between">
         <p className="text-sm font-medium text-gray-900">Total</p>
         <p className="text-2xl font-medium text-gray-900">Rs.{total}.00</p>
       </div>

       {/* Buttons */}
       {/* <div className="mt-6 flex justify-center gap-6">
         <button
           className="px-6 py-3 text-base font-semibold text-white bg-[#861d34] rounded-md shadow-md hover:bg-[#621c2b] transition-all"
         >
           Continue Shopping
         </button>

         <button
           onClick={handleCheckout}
           className="px-6 py-3 text-base font-semibold text-white bg-yellow-400 rounded-md shadow-md hover:bg-yellow-500 transition-all"
         >
           Proceed to Checkout
         </button>
       </div> */}
     </div>
   </div>

  )
}

export default CartItem