 export const createRazorpayPaymentLink = async (orderId, amount,preFillFormData,keyId, keySecret) => {
    try {
      const response = await fetch('https://api.razorpay.com/v1/payment_links', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${keyId}:${keySecret}`)}`, // Encode API keys in Base64
        },
        body: JSON.stringify({
          amount: amount*100, // Razorpay expects amount in paisa
          currency: "INR",
          description: `Payment for Order ID: ${orderId}`,
          customer: {
            name: preFillFormData.name,
            email: preFillFormData.email,
            contact: preFillFormData.mobile
          },
          notify: {
            email: true,
            sms: true
          },
          callback_url: `${window.location.origin}/payment-success`,
          callback_method: "get"
        })
      });
  
      const data = await response.json();
      if (data.short_url) {
        return data.short_url; // Razorpay provides a short URL for the payment link
      } else {
        console.error("Failed to create payment link", data);
        return null;
      }
    } catch (error) {
      console.error("Error creating payment link", error);
      return null;
    }
  };
  