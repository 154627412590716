import React from 'react';
import logo from '../assets/images/favicon/aboutlogo.png';


function Logo() {
    return (
        <div><section class="bg-white max-w-7xl mx-auto md:px-0 lg:px-0 xl:px-0 px-3">
            <h2 class="mb-4 text-4xl tracking-tight font-bold text-gray-900 ">About Our Logo</h2>

            <hr className='border-b-2 border-gray-200' />
            <div class="py-4 px-4 mx-auto max-w-screen-xl lg:py-5 lg:px-6 space-y-4">

                <div class="max-w-screen-lg text-gray-500 sm:text-lg ">

                    <div className='max-w-xl'>
                        <img src={logo} alt="logo-about"
                            className='h-44' />
                    </div>
                    <p class="mb-4 font-normal">All the activites of 'Samskar' are geared toward endowing a human being with six divine qualities - splendor, forgiveness, patience, purity, non-violence and humility. Inspirating to help build a balanced persona.</p>
                    <p class="mb-4 font-medium">Fragrant Mango and Ashoka leaves in the six corners are indicative of the well-being of an individual and family. The hexagonal symbol has been chosen with this perspective.</p>

                </div>
            </div>
        </section></div>
    )
}

export default Logo