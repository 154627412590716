// import book1 from "./assets/uploads/1.png";
// import book2 from "./assets/uploads/2.png";
// import book3 from "./assets/uploads/3.png";
// import book4 from "./assets/uploads/4.png";
// import book5 from "./assets/uploads/5.png";
// import book6 from "./assets/uploads/6.png";
// import book7 from "./assets/uploads/7.png";
// import book8 from "./assets/uploads/8.png";
// import book9 from "./assets/uploads/9.png";
// import book10 from "./assets/uploads/10.png";
// import book11 from "./assets/uploads/11.png";
// import book12 from "./assets/uploads/12.png";
// import book13 from "./assets/uploads/13.png";
// import book14 from "./assets/uploads/14.png";
// import book15 from "./assets/uploads/15.png";
// import book16 from "./assets/uploads/16.png";
// import book17 from "./assets/uploads/17.png";
// import book18 from "./assets/uploads/18.png";
// import book19 from "./assets/uploads/19.png";
// import book20 from "./assets/uploads/20.png";
// import book21 from "./assets/uploads/21.png";
// import book22 from "./assets/uploads/22.png";
// import book23 from "./assets/uploads/23.png";
// import book24 from "./assets/uploads/24.png";
// import book25 from "./assets/uploads/25.png";
// import book26 from "./assets/uploads/26.png";
// import book27 from "./assets/uploads/27.png";
// import book28 from "./assets/uploads/28.png";
// import book29 from "./assets/uploads/29.png";
// import book30 from "./assets/uploads/30.png";
// import book31 from "./assets/uploads/31.png";
// import book32 from "./assets/uploads/32.png";
// import book33 from "./assets/uploads/33.png";
// import book34 from "./assets/uploads/34.png";
// import book35 from "./assets/uploads/35.png";
// import book36 from "./assets/uploads/36.png";
// import book37 from "./assets/uploads/37.png";
// import book38 from "./assets/uploads/38.png";
// import book39 from "./assets/uploads/39.png";
// import book40 from "./assets/uploads/40.png";
// import book41 from "./assets/uploads/41.png";
// import book42 from "./assets/uploads/42.png";
// import book43 from "./assets/uploads/43.png";
// import book44 from "./assets/uploads/44.png";
// import book45 from "./assets/uploads/45.png";
// import book46 from "./assets/uploads/46.png";
// import book47 from "./assets/uploads/47.png";
// import book48 from "./assets/uploads/48.png";
// import book49 from "./assets/uploads/49.png";
// import book50 from "./assets/uploads/50.png";
// import book51 from "./assets/uploads/51.png";
// import book52 from "./assets/uploads/52.png";
// import book53 from "./assets/uploads/53.png";
// import book54 from "./assets/uploads/54.png";
// import book55 from "./assets/uploads/55.png";
// import book56 from "./assets/uploads/56.png";
// import book57 from "./assets/uploads/57.png";
// import book58 from "./assets/uploads/58.png";
// import book59 from "./assets/uploads/59.png";
// import book60 from "./assets/uploads/60.png";
// import book61 from "./assets/uploads/61.png";
// import book62 from "./assets/uploads/62.png";
// import book63 from "./assets/uploads/63.png";
// import book64 from "./assets/uploads/64.png";
// import book65 from "./assets/uploads/65.png";
// import book66 from "./assets/uploads/66.png";
// import book67 from "./assets/uploads/67.png";
// import book68 from "./assets/uploads/68.png";
// import book69 from "./assets/uploads/69.png";
// import book70 from "./assets/uploads/70.png";
// import book71 from "./assets/uploads/71.png";
// import book72 from "./assets/uploads/72.png";
// import book73 from "./assets/uploads/73.png";
// import book74 from "./assets/uploads/74.png";
// import book75 from "./assets/uploads/75.png";
// import book76 from "./assets/uploads/76.png";
// import book77 from "./assets/uploads/77.png";
// import book78 from "./assets/uploads/78.png";
// import book79 from "./assets/uploads/79.png";
// import book80 from "./assets/uploads/80.png";
// import book81 from "./assets/uploads/81.png";
// import book82 from "./assets/uploads/82.png";
// import book83 from "./assets/uploads/83.png";
// import book84 from "./assets/uploads/84.png";
// import book85 from "./assets/uploads/85.png";
// import book86 from "./assets/uploads/86.png";
// import book87 from "./assets/uploads/87.png";
// import book88 from "./assets/uploads/88.png";
// import book89 from "./assets/uploads/89.png";
// import book90 from "./assets/uploads/90.png";
// import book91 from "./assets/uploads/91.png";
// import book92 from "./assets/uploads/92.png";
// import book93 from "./assets/uploads/93.png";
// import book94 from "./assets/uploads/94.png";
// import book95 from "./assets/uploads/95.png";
// import book96 from "./assets/uploads/96.png";
// import book97 from "./assets/uploads/97.png";
// import book98 from "./assets/uploads/98.png";
// import book99 from "./assets/uploads/99.png";
// import book100 from "./assets/uploads/100.png";
// import book101 from "./assets/uploads/101.png";
// import book102 from "./assets/uploads/102.png";
// import book103 from "./assets/uploads/103.png";
// import book104 from "./assets/uploads/104.png";
// import book105 from "./assets/uploads/105.png";
// import book106 from "./assets/uploads/106.png";
// import book107 from "./assets/uploads/107.png";
// import book108 from "./assets/uploads/108.png";
// import book109 from "./assets/uploads/109.png";
// import book110 from "./assets/uploads/110.png";
// import book111 from "./assets/uploads/111.png";
// import book112 from "./assets/uploads/112.png";
// import book113 from "./assets/uploads/113.png";
// import book114 from "./assets/uploads/114.png";
// import book115 from "./assets/uploads/115.png";
// import book116 from "./assets/uploads/116.png";
// import book117 from "./assets/uploads/117.png";
// import book118 from "./assets/uploads/118.png";
// import book119 from "./assets/uploads/119.png";
// import book120 from "./assets/uploads/120.png";
// import book121 from "./assets/uploads/121.png";
// import book122 from "./assets/uploads/122.png";
// import book123 from "./assets/uploads/123.png";
// import book124 from "./assets/uploads/124.png";
// import book125 from "./assets/uploads/125.png";
// import book126 from "./assets/uploads/126.png";
// import book127 from "./assets/uploads/127.png";
// import book128 from "./assets/uploads/128.png";
// import book129 from "./assets/uploads/129.png";
// import book130 from "./assets/uploads/130.png";
// import book131 from "./assets/uploads/131.png";
// import book132 from "./assets/uploads/132.png";
// import book133 from "./assets/uploads/133.png";
// import book134 from "./assets/uploads/134.png";
// import book135 from "./assets/uploads/135.png";
// import book136 from "./assets/uploads/136.png";
// import book137 from "./assets/uploads/137.png";
// import book138 from "./assets/uploads/138.png";
// import book139 from "./assets/uploads/139.png";
// import book140 from "./assets/uploads/140.png";
// import book141 from "./assets/uploads/141.png";
// import book142 from "./assets/uploads/142.png";
// import book143 from "./assets/uploads/143.png";
// // import book144 from "./assets/uploads/144.png";
// // import book145 from "./assets/uploads/145.png";
// import book146 from "./assets/uploads/146.png";
// import book147 from "./assets/uploads/147.png";
// import book148 from "./assets/uploads/148.png";
// import book149 from "./assets/uploads/149.png";
// import book150 from "./assets/uploads/150.png";
// import book151 from "./assets/uploads/151.png";
// import book152 from "./assets/uploads/152.png";
// import book153 from "./assets/uploads/153.png";
// import book154 from "./assets/uploads/154.png";
// import book155 from "./assets/uploads/155.png";
// import book156 from "./assets/uploads/156.png";
// import book157 from "./assets/uploads/157.png";
// import book158 from "./assets/uploads/158.png";
// import book159 from "./assets/uploads/159.png";
// import book160 from "./assets/uploads/160.png";
// import book161 from "./assets/uploads/161.png";
// import book162 from "./assets/uploads/162.png";
// import book163 from "./assets/uploads/163.png";
// import book164 from "./assets/uploads/164.png";
// import book165 from "./assets/uploads/165.png";
// import book166 from "./assets/uploads/166.png";
// import book167 from "./assets/uploads/167.png";
// import book168 from "./assets/uploads/168.png";
// import book169 from "./assets/uploads/169.png";
// import book170 from "./assets/uploads/170.png";
// import book171 from "./assets/uploads/171.png";
// import book172 from "./assets/uploads/172.png";
// import book173 from "./assets/uploads/173.png";
// import book174 from "./assets/uploads/174.png";
// import book175 from "./assets/uploads/175.png";
// import book176 from "./assets/uploads/176.png";
// import book177 from "./assets/uploads/177.png";
// import book178 from "./assets/uploads/178.png";
// import book179 from "./assets/uploads/179.png";
// import book180 from "./assets/uploads/180.png";
// import book181 from "./assets/uploads/181.png";
// import book182 from "./assets/uploads/182.png";
// import book183 from "./assets/uploads/183.png";
// import book184 from "./assets/uploads/184.png";
// import book185 from "./assets/uploads/185.png";
// import book186 from "./assets/uploads/186.png";
// import book187 from "./assets/uploads/187.png";
// import book188 from "./assets/uploads/188.png";
// import book189 from "./assets/uploads/189.png";
// import book190 from "./assets/uploads/190.png";
// import book191 from "./assets/uploads/191.png";
// import book192 from "./assets/uploads/192.png";
// import book193 from "./assets/uploads/193.png";
// import book194 from "./assets/uploads/194.png";
// import book195 from "./assets/uploads/195.png";
// import book196 from "./assets/uploads/196.png";
// import book197 from "./assets/uploads/197.png";
// import book198 from "./assets/uploads/198.png";
// import book199 from "./assets/uploads/199.png";
// import book200 from "./assets/uploads/200.png";
// import book201 from "./assets/uploads/201.png";
// import book202 from "./assets/uploads/202.png";
// import book203 from "./assets/uploads/203.png";
// import book204 from "./assets/uploads/204.png";
// import book205 from "./assets/uploads/205.png";
// import book206 from "./assets/uploads/206.png";
// import book207 from "./assets/uploads/207.png";
// import book208 from "./assets/uploads/208.png";
// import book209 from "./assets/uploads/209.png";
// import book210 from "./assets/uploads/210.png";
// import book211 from "./assets/uploads/211.png";
// import book212 from "./assets/uploads/212.png";
// import book213 from "./assets/uploads/213.png";
// import book214 from "./assets/uploads/214.png";
// import book215 from "./assets/uploads/215.png";
// import book216 from "./assets/uploads/216.png";
// import book217 from "./assets/uploads/217.png";
// import book218 from "./assets/uploads/218.png";
// import book219 from "./assets/uploads/219.png";
// import book220 from "./assets/uploads/220.png";
// import book221 from "./assets/uploads/221.png";
// import book222 from "./assets/uploads/222.png";
// import book223 from "./assets/uploads/223.png";
// import book224 from "./assets/uploads/224.png";
// import book225 from "./assets/uploads/225.png";
// import book226 from "./assets/uploads/226.png";
// import book227 from "./assets/uploads/227.png";
// import book228 from "./assets/uploads/228.png";
// import book229 from "./assets/uploads/229.png";
// import book230 from "./assets/uploads/230.png";
// import book231 from "./assets/uploads/231.png";
// import book232 from "./assets/uploads/232.png";
// import book233 from "./assets/uploads/233.png";
// import book234 from "./assets/uploads/234.png";
// import book235 from "./assets/uploads/235.png";
// import book236 from "./assets/uploads/236.png";
// import book237 from "./assets/uploads/237.png";
// import book238 from "./assets/uploads/238.png";
// import book239 from "./assets/uploads/239.png";
// import book240 from "./assets/uploads/240.png";
// import book241 from "./assets/uploads/241.png";

export const bookdata =
  [
    {
      id: 1,
      booktype: "general books",
      booknameinenglish: "Manna ki varnmala",
      booknameinhindi: "मन्ना की वर्णमाला ",
      writer: "कुंकुम जोशी ",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "200",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1cjfDJQ-zEKXnKD0V80b6rnNclq3R6oVT/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "कविताओं के माध्यम से हिंदी के अक्षर सिखाना",
      illustrator: "नित्या पंत ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "52",
      discountedprice: "180",
      image: "/uploads/1.png",
      isActive: "1",
      isfeatured: "1"
    },
    {
      id: 2,
      booktype: "general books",
      booknameinenglish: "Bansuri",
      booknameinhindi: "बाँसुरी ",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2009, 2023",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1JsClxW_hu1Li7qzTukOlWRXMfR2FECu5/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "कविता के माध्यम से बाँसुरी के बारे में जानें ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "9",
      discountedprice: "72",
      image: "/uploads/2.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 3,
      booktype: "general books",
      booknameinenglish: "Van kya hai",
      booknameinhindi: "वन क्या है ",
      writer: "हेम भटनागर ",
      agegroup: "8-14 years",
      edition: "2005, 2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1u4BireyyIkEdmEKdXM3IbKishWBKjzEQ/view?usp=sharing",
      category: "hindi, children, 8 - 14 years",
      description: "जैनेन्द्र जैन की कहानी तत्सत का नाट्य रूपांतरण बच्चों के लिए ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "72",
      image: "/uploads/3.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 4,
      booktype: "general books",
      booknameinenglish: "Neha ka janmdin",
      booknameinhindi: "नेहा का जन्मदिन",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1992, 2015",
      publisher: "Hem Aunty Publications",
      price: "60",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/11lZZ4ev-osO3x8DoF-fv0zm1T4JOg2Q7/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "बच्चों के लिये जन्मदिन का गाना स्वरलिपि के साथ।",
      illustrator: "सुचित्रा चौहान ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "8",
      discountedprice: "54",
      image: "/uploads/4.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 5,
      booktype: "general books",
      booknameinenglish: "Joo joo ki hui larai",
      booknameinhindi: "जूँ जूँ की हुई लड़ाई",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2009",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1nFh3omU8ZSweEFGT8nUJawvjQXY84-uo/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "लोक कथा पर आधारित बाल नाटक ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "72",
      image: "/uploads/5.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 6,
      booktype: "general books",
      booknameinenglish: "Dhela aur Patti",
      booknameinhindi: "ढेला और पत्ती",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2011, 2015",
      publisher: "Hem Aunty Publications",
      price: "30",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1tm0yXZh1NrtyrmpsVe3VT64jVp0WnEsv/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "अवधी लोक कथा पर आधारित यह बहुत छोटी कहानी है मिट्टी के ढेले की और पत्ती की।",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "4",
      discountedprice: "27",
      image: "/uploads/6.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 7,
      booktype: "general books",
      booknameinenglish: "Sa re ga ma pa",
      booknameinhindi: "सा रे गा मा पा",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2013",
      publisher: "Hem Aunty Publications",
      price: "90",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Kx6jR8cHp1iP9vybT2cfd3is0CMik-O-/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "छोटे बच्चों को संगीत शिक्षा के प्रारंभ में सात शुद्ध स्वरों का परिचय देने के लिये लिखा गया नाटक",
      illustrator: "सीमा पांडेय, चेतन शास्त्री  ",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "18",
      discountedprice: "81",
      image: "/uploads/7.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 8,
      booktype: "general books",
      booknameinenglish: "Sa re ga ma pa",
      booknameinhindi: "",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2013",
      publisher: "Hem Aunty Publications",
      price: "90",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1K8yJDPQPcbhOkUrZwxmjoK022IO9Jxew/view?usp=sharing",
      category: "English, children, 2.5-8",
      description: "A Musical Play to familiarise children with the seven notes of Indian Classical Music.",
      illustrator: "Seema pandey, Chetan Shastri",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "18",
      discountedprice: "81",
      image: "/uploads/8.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 9,
      booktype: "general books",
      booknameinenglish: "Hindi ke akshar",
      booknameinhindi: "हिंदी के अक्षर",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2005, 2011",
      publisher: "Hem Aunty Publications",
      price: "350",
      language: "Hindi-English",
      pdflink: "https://drive.google.com/file/d/1JVL4U8Lo0Uu0A1DBx7OEhyfbpKkeePqw/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "Literacy - Teaching Hindi language to English speaking children and adults.",
      illustrator: "Hem Bhatnagar",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "48",
      discountedprice: "315",
      image: "/uploads/9.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 10,
      booktype: "general books",
      booknameinenglish: "Good Morning Everyone",
      booknameinhindi: "",
      writer: "Hem Bhatnagar",
      agegroup: "2.5-8 years",
      edition: "1992, 2015",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1gjR5cjnMntSvCuTY_sihjycVWhoGrESf/view?usp=sharing",
      category: "English, children, 2.5-8",
      description: "Morning greeting song with notation",
      illustrator: "Suchitra Chauhan",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "6",
      discountedprice: "72",
      image: "/uploads/10.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 11,
      booktype: "general books",
      booknameinenglish: "Subah Subah Pranam Karen",
      booknameinhindi: "सुबह सुबह प्रणाम करें",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1992, 2015",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1eB9IqvvIEdvEBscvUXybHzvo9-8CIQSi/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "Let`s bow to everyone in the morning - Morning song with notation",
      illustrator: "सुचित्रा चौहान ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "6",
      discountedprice: "72",
      image: "/uploads/11.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 12,
      booktype: "general books",
      booknameinenglish: "Kamal ka Phool",
      booknameinhindi: "कमल का फूल",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2007",
      publisher: "Hem Aunty Publications",
      price: "60",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1YRqw3KeaZXqS47Cmnn6hXsXgbip1j6ac/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "गौतम बुद्ध की जातक कहानी पर आधारित नाटक",
      illustrator: "-",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "54",
      image: "/uploads/12.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 13,
      booktype: "general books",
      booknameinenglish: "Chanda mama chandni de",
      booknameinhindi: "चंदा मामा चाँदनी दे",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2002, 2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "35",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1KyAF9f0q99ML7vGrSV-0gumGDKZnnjlI/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "चंदा मामा से चाँदी के तार लेकर बच्चे क्या करेंगे,जानिये",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "31",
      image: "/uploads/13.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 14,
      booktype: "general books",
      booknameinenglish: "Nanhi cheenti",
      booknameinhindi: "नन्हीं चींटी",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2019",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1lm3WZqWPIA2Fgn312YjaAwEjeuhfrR2T/view?usp=sharing",
      category: "hindi/children, 2.5-8 years, prose",
      description: "दादी की रसोई की नन्हीं चींटी से बच्चों ने क्या सीखा, जानिये इस कविता रूपी कहानी से",
      illustrator: "नीतू ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/14.png",
      isActive: "1",
      isfeatured: "1"
    },
    {
      id: 15,
      booktype: "general books",
      booknameinenglish: "With Folded Hands",
      booknameinhindi: "With Folded Hands",
      writer: "Hem Bhatnagar",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "90",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1kI3NKAy9icAQCKy-rfVZniDykT2HmSDy/view?usp=sharing",
      category: "English, children, 2.5-8",
      description: "Poem for all our revered teachers",
      illustrator: "Suchitra Chauhan",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "6",
      discountedprice: "81",
      image: "/uploads/15.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 16,
      booktype: "general books",
      booknameinenglish: "Naman karen hum sab guruon ko",
      booknameinhindi: "नमन करें हम सब गुरुओं को",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2018, 2023",
      publisher: "Hem Aunty Publications",
      price: "90",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Ba_CgpxFcahkeeNH2ZxAbNa2z6huQH5s/view?usp=sharing",
      category: "hindi/children, 2.5-8 years, prose",
      description: "कौन हैं हमारे गुरु? बाल गीत",
      illustrator: "सुचित्रा चौहान ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "u",
      discountedprice: "81",
      image: "/uploads/16.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 17,
      booktype: "general books",
      booknameinenglish: "Aap hi devi, Aap hi devta",
      booknameinhindi: "आप ही देवी, आप ही देवता",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2007, 2018",
      publisher: "Hem Aunty Publications",
      price: "60",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1aXp5AeJIGnKvc8MylCg5Mzon-rsUqGAa/view?usp=sharing",
      category: "hindi/children, 2.5-8 years, prose",
      description: "छोटे बच्चों को हेम दादी ने बताया कि वास्तव में देवी-देवता कौन होते हैं - प्रश्न उत्तर के माध्यम से ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "18",
      discountedprice: "54",
      image: "/uploads/17.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 18,
      booktype: "general books",
      booknameinenglish: "You are God!You are Goddess!",
      booknameinhindi: "You are God! You are Goddess!",
      writer: "Hem Bhatnagar",
      agegroup: "2.5-8 years",
      edition: "2007, 2018",
      publisher: "Hem Aunty Publications",
      price: "60",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1zftkUJbAeDocPNrGuGB36Om3Ea9TnbaC/view?usp=drive_link",
      category: "english/children, 2.5-8 years. prose",
      description: "Through the questions asked by children, Hem Dadi tried to tell them about Gods and Goddesses.",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "18",
      discountedprice: "54",
      image: "/uploads/18.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 19,
      booktype: "general books",
      booknameinenglish: "Baal Ram katha",
      booknameinhindi: "बाल रामकथा",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2018, 2023",
      publisher: "Hem Aunty Publications",
      price: "200",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1FYg5bDHHMn1361a6_iNfZGIMpzT3pMif/view?usp=sharing",
      category: "hindi, children, 2.5-8 years, prose",
      description: "बच्चों के समझने योग्य सरल राम कथा। तेजसी ने जब इस पुस्तक के चित्र बनाए, वह दस वर्ष की थीं",
      illustrator: "तेजसी भटनागर ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "22",
      discountedprice: "180",
      image: "/uploads/19.png",
      isActive: "1",
      isfeatured: "1"
    },
    {
      id: 20,
      booktype: "general books",
      booknameinenglish: "Chal mere ghore tik tik tik",
      booknameinhindi: "चल मेरे घोड़े टिक टिक टिक",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2018",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1LsoUjBsCiNSndSEYCWQ_p-hyqiaUePRk/view?usp=sharing",
      category: "hindi, children, 2.5-8 years, poetry",
      description: "मेरे घोड़े के साथ मैंने गौरया, नदी, बादल और शिक्षक से क्या सीखा? आइये जानते हैं इस गीत से ",
      illustrator: "तेजसी भटनागर ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "6",
      discountedprice: "72",
      image: "/uploads/20.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 21,
      booktype: "general books",
      booknameinenglish: "Harsingar ka geet",
      booknameinhindi: "हरसिंगार का गीत",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2022",
      publisher: "Hem Aunty Publications",
      price: "15",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1awHaS8hk-GNjdVcVQWmBX3VO9zIn6Aab/view?usp=sharing",
      category: "hindi, children, 2.5-8 years, poetry",
      description: "जब धीरे-धीरे चुपके-चुपके हरसिंगार के फूल गिरे तो कैसा गीत बना ?",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "4",
      discountedprice: "13",
      image: "/uploads/21.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 22,
      booktype: "general books",
      booknameinenglish: "Haath mein haath mila ke",
      booknameinhindi: "हाथ में हाथ मिलाके",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2018",
      publisher: "Hem Aunty Publications",
      price: "30",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1KEhTyfx48Af5SIBfODG1x8rzXbavdDqG/view?usp=sharing",
      category: "hindi, children, 2.5-8 years, poetry",
      description: "स्वर लिपि के साथ संख्या एक से बीस तब की गिनती सिखाने का गीत",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "6",
      discountedprice: "27",
      image: "/uploads/22.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 23,
      booktype: "general books",
      booknameinenglish: "Hum bachche hain",
      booknameinhindi: "हम बच्चे हैं",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2018",
      publisher: "Hem Aunty Publications",
      price: "30",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1gEpepsChkFlA03T4CHsTeI4vAn7i_lPY/view?usp=sharing",
      category: "hindi, children, 2.5-8 years, poetry",
      description: "हम बच्चे हैं सूरज-चंदा घर में करें उजाला, बाहर करें उजाला - बाल गीत स्वर लिपि के साथ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "6",
      discountedprice: "27",
      image: "/uploads/23.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 24,
      booktype: "general books",
      booknameinenglish: "Kora jamal shahi (Baal Geet)",
      booknameinhindi: "कोड़ा जमाल शाही",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2018",
      publisher: "Hem Aunty Publications",
      price: "90",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1et7ZSMi5asoChuIj933sTOUYBeUQ0Wh5/view?usp=sharing",
      category: "hindi, children, 2.5-8 years, poetry",
      description: "बच्चों के लिये दस गीत",
      illustrator: "तेजसी भटनागर ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "81",
      image: "/uploads/24.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 25,
      booktype: "general books",
      booknameinenglish: "Prakhar banenge suraj jaise",
      booknameinhindi: "प्रखर बनेंगे सूरज जैसे",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2018",
      publisher: "Hem Aunty Publications",
      price: "90",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1ogDh4LJCs4qZxtuq35wGqMornAOE6Nj6/view?usp=sharing",
      category: "hindi, children, 2.5-8 years, poetry",
      description: "बाल प्रार्थनाएँ स्वर-लिपि के साथ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "81",
      image: "/uploads/25.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 26,
      booktype: "general books",
      booknameinenglish: "Ek din Suraj ne socha",
      booknameinhindi: "एक दिन सूरज ने सोचा",
      writer: "हेम भटनागर ",
      agegroup: "8-14 years",
      edition: "2002, 2018",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1p0nSXsjuUot99XKYQGAnnpB0qZSlSk-Y/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "एक दिन सूरज ने सोचा कि जब मैं थक जाऊँगा, मेरा काम कौन करेगा? रवींद्र नाथ टैगोर के उद्धरण पर लिखी गई कहानी",
      illustrator: "तेजसी भटनागर ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "18",
      discountedprice: "135",
      image: "/uploads/26.png",
      isActive: "1",
      isfeatured: "1"
    },
    {
      id: 27,
      booktype: "general books",
      booknameinenglish: "Ganga",
      booknameinhindi: "गंगा ",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "70",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1sg7aEuVlzdIZ8rHOo5bNa3H5Gji3uydl/view?usp=sharing",
      category: "children, 2.5-8 years. prose",
      description: "गंगा नदी का गीत बच्चों के लिए ",
      illustrator: "आशा अग्रवाल ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "63",
      image: "/uploads/27.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 28,
      booktype: "general books",
      booknameinenglish: "Wah! Pyari Chowkri",
      booknameinhindi: "वाह ! प्यारी चौकड़ी ",
      writer: "मधुलिका अग्रवाल ",
      agegroup: "2.5-8 years",
      edition: "2003",
      publisher: "Hem Aunty Publications",
      price: "30",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1W7DAC-ajKJcTga19f_o1IZoSHl_LFW7D/view?usp=sharing",
      category: "hindi, children, 2.5 - 8 years",
      description: "कहानी गुलमोहर के पेड़ की और बरगद बाबा की। कहानी रंग-बिरंगी पतंगों की, चिड़िया, गिलहरी, बंदर, बच्चों की",
      illustrator: "गरिमा ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "27",
      image: "/uploads/28.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 29,
      booktype: "general books",
      booknameinenglish: "Mehek ka mogra",
      booknameinhindi: "महक का मोगरा ",
      writer: "मधुलिका अग्रवाल ",
      agegroup: "2.5-8 years",
      edition: "2011",
      publisher: "Hem Aunty Publications",
      price: "60",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/165mRHBW3UjeWwbS8vVUYknAzD5QVYAAY/view?usp=sharing",
      category: "hindi, children, 2.5 - 8 years",
      description: "महक के जन्मदिन पर उसे मिला मोगरा के फूलों का गुच्छा। उन फूलों की यात्रा की कहानी है यह",
      illustrator: "आशा अग्रवाल ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "54",
      image: "/uploads/29.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 30,
      booktype: "general books",
      booknameinenglish: "meethi bandariya ka janmdin",
      booknameinhindi: "मीठी बंदरिया का जन्मदिन ",
      writer: "मधुलिका अग्रवाल ",
      agegroup: "2.5-8 years",
      edition: "2006",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1VWbsZVozrNiA_Z27QzkhTgw3arysoGhk/view?usp=sharing",
      category: "hindi, children, 2.5 - 8 years",
      description: "मीठी बंदरिया के जन्मदिन पर उसको मिला सबक - कहानी मीठी बंदरिया की ",
      illustrator: "यशी भटनागर ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/30.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 31,
      booktype: "general books",
      booknameinenglish: "Lovely Foursome",
      booknameinhindi: "",
      writer: "Madhulika Agarwal",
      agegroup: "2.5-8 years",
      edition: "2003, 2013,2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1xsPCYN9lI6ZcwYoVmXq_AxviKyCoPFFx/view?usp=sharing",
      category: "English, children, 2.5 - 8 years",
      description: "Story of a beautiful Gulmohar tree and a wise Banyan tree with some animal characters and colourful kites.",
      illustrator: "Garima",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "90",
      image: "/uploads/31.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 32,
      booktype: "general books",
      booknameinenglish: "Our Doll",
      booknameinhindi: "",
      writer: "Madhulika Agarwal",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "70",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1JISJ9vokW9l30Iuymxbw-M6QIdsPV5XE/view?usp=sharing",
      category: "English, children, 2.5 - 8 years",
      description: "Who owns the doll? Let`s find out from this story.",
      illustrator: "Asha Agarwal",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "63",
      image: "/uploads/32.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 33,
      booktype: "general books",
      booknameinenglish: "Rani ka kangan",
      booknameinhindi: "रानी का कंगन ",
      writer: "अमित भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2004, 2023",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1qcnWi7yNBcJ_ssx4tv07ZHz5Q9M4687t/view?usp=sharing",
      category: "hindi, children, 2.5 - 8 years",
      description: "राजा-रानी, मछली, मछुआरे-मछुआरिन के बीच बनी यह कहानी ‘रानी का कंगन’",
      illustrator: "Suvidha Mistry",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "32",
      discountedprice: "135",
      image: "/uploads/33.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 34,
      booktype: "general books",
      booknameinenglish: "Jangal Mein Mor Nacha",
      booknameinhindi: "जंगल में मोर नाचा",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "1998, 2015",
      publisher: "Hem Aunty Publications",
      price: "50",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/15S3ZBgB3ITSJ_DJVdb7hZud7mwseZ6SY/view?usp=sharing",
      category: "hindi, children, 2.5-8",
      description: "पशु-पक्षियों की बात नाच-गाकर, नाटक के माध्यम से जानें",
      illustrator: "Seema pandey",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "45",
      image: "/uploads/34.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 35,
      booktype: "general books",
      booknameinenglish: "Beeti Vibhavari Jaag ri",
      booknameinhindi: "बीती विभावरी जाग री",
      writer: "हेम भटनागर ",
      agegroup: "8-14 years",
      edition: "2012, 2023",
      publisher: "Hem Aunty Publications",
      price: "160",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/18PYK-COhQxdFwc3xcNn5WGC8cb5rQQsS/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "हिंदी भाषा कैसे विकसित हुई, उसकी यात्रा संगीतमय नाटक के रूप में। इसकी प्रस्तुति स्कूलों, काॅलिजों और संस्थाओं में हिंदी में रुचि उत्पन्न करने के लिये करवाई जा सकती है",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "40",
      discountedprice: "144",
      image: "/uploads/35.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 36,
      booktype: "general books",
      booknameinenglish: "Manav Dharm ke prerak Sant Kabir",
      booknameinhindi: "मानव धर्म के प्रेरक संत कबीर",
      writer: "हेम भटनागर ",
      agegroup: "8-14 years",
      edition: "1998, 2013",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/14adCv19To8_5pXNb3XtpihvqQbU_KdNc/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "संत कबीर की जीवनी बच्चों के लिए ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "48",
      discountedprice: "135",
      image: "/uploads/36.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 37,
      booktype: "general books",
      booknameinenglish: "Promoter of humanity Sant Kabir",
      booknameinhindi: "",
      writer: "Hem Bhatnagar",
      agegroup: "8-14 years",
      edition: "1998, 2002, 2023",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "English",
      pdflink: "https://drive.google.com/file/d/14nCiA1rLtWs2mJSxBRjAIfL_rEc1dnDp/view?usp=sharing",
      category: "English, adults, prose",
      description: "Biography of Kabir, Translated by neeti shrivastava",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "48",
      discountedprice: "135",
      image: "/uploads/37.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 38,
      booktype: "general books",
      booknameinenglish: "kuhu kuhu - kaanv kaanv",
      booknameinhindi: "कुहूँ कुहूँ, काँव काँव",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2005",
      publisher: "Hem Aunty Publications",
      price: "60",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1X4DI-tefM9dJXw9UUqUAcrZeXJ2xTHtx/view?usp=sharing",
      category: "hindi, children, 2.5-8 years",
      description: "कविता कौवे और कोयल की ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "8",
      discountedprice: "54",
      image: "/uploads/38.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 39,
      booktype: "general books",
      booknameinenglish: "Tara Gaon",
      booknameinhindi: "तारा गाँव",
      writer: "हेम भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2004, 2015",
      publisher: "Hem Aunty Publications",
      price: "90",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1ZA4gY1kwTKTm-Ek-8-kRhmXFJSLC-G_k/view?usp=sharing",
      category: "hindi, children, 2.5 - 8 years",
      description: "क्या हुआ जब गाँव से चोर बूढ़ों को लेकर भाग गए ?",
      illustrator: "सुविधा मिस्त्री ",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "81",
      image: "/uploads/39.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 40,
      booktype: "general books",
      booknameinenglish: "karamati bindi",
      booknameinhindi: "करामाती बिंदी",
      writer: "हेम भटनागर ",
      agegroup: "8-14 years",
      edition: "2008",
      publisher: "Hem Aunty Publications",
      price: "60",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/10FblReH54HgtgdgrLgkPQ996PlVxXjEr/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "रेवली रम्या और उसकी करामाती बिंदी की संगीतात्मक कहानी",
      illustrator: "सुविधा मिस्त्री ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "54",
      image: "/uploads/40.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 41,
      booktype: "general books",
      booknameinenglish: "Tejali tatha anya kahaniyan (Edited)",
      booknameinhindi: "तेजली तथा अन्य कहानियाँ",
      writer: "संपादन : हेम भटनागर ",
      agegroup: "8-14 years",
      edition: "1996-1997",
      publisher: "Hem Aunty Publications",
      price: "60",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1dcWfbnyFsbtWnBCMJ8kQ_d9taxarDNnQ/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "एसोसिएशन आॅफ राइटर्स ऐंड इलस्ट्रेटर्स फाॅर चिल्ड्रेन की बाल लेखिकाओं द्वारा लिखित कहानी संग्रह। विषय - पर्यावरण।",
      illustrator: "Swapan Sarcar",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "100",
      discountedprice: "54",
      image: "/uploads/41.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 42,
      booktype: "general books",
      booknameinenglish: "How to write correct Hindi",
      booknameinhindi: "",
      writer: "Hem Bhatnagar",
      agegroup: "8-14 years",
      edition: "2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1RrYdzr8HFmF-p6G5_29XYLJbloF16-6O/view?usp=sharing",
      category: "hindi, children, 8 -14 years",
      description: "",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "36",
      discountedprice: "135",
      image: "/uploads/42.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 43,
      booktype: "general books",
      booknameinenglish: "Hindi Shudh Kaise Likhen",
      booknameinhindi: "हिंदी शुद्ध कैसे लिखें ?",
      writer: "हेम भटनागर ",
      agegroup: "8-14 years",
      edition: "2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1V2OABvmM4vxjWj4p4w7awUXL7n_cKMZs/view?usp=sharing",
      category: "hindi, children, 8 -14 years",
      description: "बच्चों को हिंदी भाषा सिखाने के लिए बनाई गयी पुस्तक ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "36",
      discountedprice: "135",
      image: "/uploads/43.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 44,
      booktype: "general books",
      booknameinenglish: "Hamari Kahaniyan",
      booknameinhindi: "हमारी कहानियाँ ",
      writer: "मधुलिका अग्रवाल ",
      agegroup: "8-14 years",
      edition: "2002, 2023",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1eB98oSMB-aVrzHkCbUllM7lmlZWlIEjT/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "भारतीय परिवेश में लिखी कहानियों का संग्रह ",
      illustrator: "बिंदुलिका शर्मा ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "22",
      discountedprice: "135",
      image: "/uploads/44.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 45,
      booktype: "general books",
      booknameinenglish: "phulo ke babuji",
      booknameinhindi: "फूलो के बाबूजी ",
      writer: "मधुलिका अग्रवाल ",
      agegroup: "8-14 years",
      edition: "2007, 2023",
      publisher: "Hem Aunty Publications",
      price: "180",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/15CFymT-qeszKo62Xrw8y3qxoqW8r8Xtj/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "हिंदी अकादमी द्वारा पुरस्कृत कहानी संग्रह ",
      illustrator: "अविनाश ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "32",
      discountedprice: "162",
      image: "/uploads/45.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 46,
      booktype: "general books",
      booknameinenglish: "vanmali",
      booknameinhindi: "वनमाली ",
      writer: "मधुलिका अग्रवाल ",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "180",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1lir3ozIcaPy0PXJTmIvMWaE9-2PKL2II/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "बाल मन की उदारता, साहस और संकल्प का परिचय देती तीन कहानियों का संग्रह ",
      illustrator: "आशा अग्रवाल ",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "40",
      discountedprice: "162",
      image: "/uploads/46.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 47,
      booktype: "general books",
      booknameinenglish: "Gaanv ganit",
      booknameinhindi: "गाँव गणित ",
      writer: "अमित भटनागर ",
      agegroup: "8-14 years",
      edition: "2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1fxJOCYlFa6h05n0TkeeKeY2xHusD_Sr-/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "बच्चों के लिए दैनिक जीवन में गणित ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Mathematics",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "22",
      discountedprice: "90",
      image: "/uploads/47.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 48,
      booktype: "general books",
      booknameinenglish: "Bhanai",
      booknameinhindi: "भणाई ",
      writer: "अमित भटनागर, जयश्री  ",
      agegroup: "8-14 years",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1XCNE7MQd_3UScvu1HEbibOploDHhbMty/view?usp=sharing",
      category: "hindi, children, 8-14 years",
      description: "गाँवों में शिक्षा की स्थिति दर्शाता नाटक ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "44",
      discountedprice: "135",
      image: "/uploads/48.png",
      isActive: "1",
      isfeatured: "1"
    },
    {
      id: 49,
      booktype: "general books",
      booknameinenglish: "Jihi Van mein Kreera Kari (Autobiography -2)",
      booknameinhindi: "जिहिं वन में क्रीड़ा करी",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2010",
      publisher: "Hem Aunty Publications",
      price: "350",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1s7xT1irQZ323D722NY52K4clQaM8hz2W/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "आत्मकथा (भाग 2) जानकी देवी मेमोरियल कॉलेज में अध्यापन काल ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "200",
      discountedprice: "315",
      image: "/uploads/49.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 50,
      booktype: "general books",
      booknameinenglish: "Bandish jo Maine Gayi (Autobiography -1)",
      booknameinhindi: "बंदिश जो मैंने गाई (आत्मकथा -भाग1)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2010",
      publisher: "Hem Aunty Publications",
      price: "450",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Y0w_EguBG9Ku3Maeif_fawPe5mkLJj8S/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "आत्मकथा (भाग 1) ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "350",
      discountedprice: "405",
      image: "/uploads/50.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 51,
      booktype: "general books",
      booknameinenglish: "Hum Vriksha Hain",
      booknameinhindi: "हम वृक्ष हैं",
      writer: "संकलन - संपादन : हेम भटनागर",
      agegroup: "Adults",
      edition: "2016",
      publisher: "Hem Aunty Publications",
      price: "800",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1KELFAfYarBvRKGHSvAjYnPP61mDx4Vps/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "वृक्षों और पेड़ पौधों पर पुस्तक, साहित्यिक सन्दर्भों के साथ  ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "170",
      discountedprice: "720",
      image: "/uploads/51.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 52,
      booktype: "general books",
      booknameinenglish: "Sangeet Sandarbh Laghu Kosh",
      booknameinhindi: "संगीत संदर्भ लघु कोश",
      writer: "संकलन - संपादन : हेम भटनागर",
      agegroup: "Adults",
      edition: "2015",
      publisher: "Hem Aunty Publications",
      price: "1100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1kmbVhcRTwdYno5h_hKqfk_aUD3L8v2PQ/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "गायन, वादन, नृत्य आदि संगीत के विविध पहलुओं पर लघु कोष  ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "470",
      discountedprice: "990",
      image: "/uploads/52.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 53,
      booktype: "general books",
      booknameinenglish: "Samskar aur Samskriti (Hard bound)",
      booknameinhindi: "संस्कार और संस्कृति (हार्ड बाउंड)",
      writer: "संकलन - संपादन : हेम भटनागर",
      agegroup: "Adults",
      edition: "1996-2019",
      publisher: "Hem Aunty Publications",
      price: "600",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/13evnZe9e2sUy0Su9Eh6chK0M6sEnfuS-/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "भारतीय संस्कृति और संस्कारों पर आकर्षक पुस्तक ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "132",
      discountedprice: "540",
      image: "/uploads/53.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 54,
      booktype: "general books",
      booknameinenglish: "Samskar aur Samskriti (Paper back)",
      booknameinhindi: "संस्कार और संस्कृति (पेपर बैक)",
      writer: "संकलन - संपादन : हेम भटनागर",
      agegroup: "Adults",
      edition: "1996-2019",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/13evnZe9e2sUy0Su9Eh6chK0M6sEnfuS-/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "132",
      discountedprice: "450",
      image: "/uploads/54.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 55,
      booktype: "general books",
      booknameinenglish: "Sampreshan",
      booknameinhindi: "संप्रेशन",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2013",
      publisher: "Hem Aunty Publications",
      price: "325",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Kx2dwkmUah6tg3kkEXn8m-YiCRLikhlE/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "विविध प्रकार के नाटकों का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "122",
      discountedprice: "292",
      image: "/uploads/55.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 56,
      booktype: "general books",
      booknameinenglish: "Sanjhi Eeta ki (Adult Education)",
      booknameinhindi: "साँझी ईता की (प्रौढ़ शिक्षा)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2014",
      publisher: "Hem Aunty Publications",
      price: "120",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1l3NfFUUfeX27BiLBgVMB5itXdpI2muj1/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "ग्रामीण महिलाओं में जागरूकता के लिए लिखे गए नाटक ",
      illustrator: "Neetu",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "24",
      discountedprice: "108",
      image: "/uploads/56.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 57,
      booktype: "general books",
      booknameinenglish: "Maina ka suhaag (Adult Education)",
      booknameinhindi: "मैना का सुहाग (प्र्रोढ़ शिक्षा)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2009, 2014",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1y0MEJ5LT-QS11EpBhSELzGxlbPVEHDkS/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "ग्रामीण महिलाओं में साक्षरता के लिए लिखे गए नाटक ",
      illustrator: "Neetu",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "28",
      discountedprice: "90",
      image: "/uploads/57.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 58,
      booktype: "general books",
      booknameinenglish: "Astha ki bhumi par",
      booknameinhindi: "आस्था की भूमि पर",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2007, 2013",
      publisher: "Hem Aunty Publications",
      price: "350",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1EzhIoXbjARDQdjvvmTfV0ogE4iC30xOY/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "विविध प्रकार के लेखों का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "118",
      discountedprice: "315",
      image: "/uploads/58.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 59,
      booktype: "general books",
      booknameinenglish: "Lahar mein geet gaye Jayshankar Prasad Ne",
      booknameinhindi: "‘लहर’ में गीत गाए जयशंकर प्रसाद ने",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2013",
      publisher: "Hem Aunty Publications",
      price: "250",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1hNf82bhKFSojJOzIX_k8fU2pKOM49yL2/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "साहित्यकार जय शंकर प्रसाद के साहित्य पर लेख  ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "82",
      discountedprice: "225",
      image: "/uploads/59.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 60,
      booktype: "general books",
      booknameinenglish: "Samvadi swar",
      booknameinhindi: "संवादी स्वर",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2003, 2023",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1o204ebJy1HtqzmfX0YfqAmWjQN7mfzjx/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "विविध विषयों पर लेखों का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "94",
      discountedprice: "450",
      image: "/uploads/60.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 61,
      booktype: "general books",
      booknameinenglish: "Dhaga Jyon toote tyon jori (Edited) (Kabir)",
      booknameinhindi: "धागा ज्यूँ टूटे त्यूँ जोरि",
      writer: "संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2013",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1ng9AI4563biu3N8DiZcOQXwNZlkFTYKx/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "कबीर की साखी धागा ज्यूँ टूटे त्यूँ जोरि पर लेखों का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "24",
      discountedprice: "72",
      image: "/uploads/61.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 62,
      booktype: "general books",
      booknameinenglish: "Pancho Naubat Baajati (Kabir) (Hard Bound)",
      booknameinhindi: "पाँचों नोबत बाजती (कबीर) (हार्ड बाउंड)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2008",
      publisher: "Hem Aunty Publications",
      price: "250",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1e53hThcPTH5i5xqKLoYfF62FcbouX88l/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "संत कबीर की वाणी  पर लेखों का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "152",
      discountedprice: "225",
      image: "/uploads/62.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 63,
      booktype: "general books",
      booknameinenglish: "Pancho Naubat Baajati (Kabir) (Paper Back Edition)",
      booknameinhindi: "पाँचों नोबत बाजती (कबीर) (पेपर बैक)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2008",
      publisher: "Hem Aunty Publications",
      price: "175",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1e53hThcPTH5i5xqKLoYfF62FcbouX88l/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "152",
      discountedprice: "157",
      image: "/uploads/63.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 64,
      booktype: "general books",
      booknameinenglish: "Philately (Stamp Collection) Edited",
      booknameinhindi: "टिकट संचयन",
      writer: "एस डी सिंह ",
      agegroup: "Adults",
      edition: "2008",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "English",
      pdflink: "https://drive.google.com/file/d/14O3GUui2Hcs7j2-oszZ65QXgdhLCAs9q/view?usp=sharing",
      category: "english, adults, prose",
      description: "Essays (Stamp collection)",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "80",
      discountedprice: "450",
      image: "/uploads/64.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 65,
      booktype: "general books",
      booknameinenglish: "Daadi Nani Kaho Kahani",
      booknameinhindi: "दादी नानी, कहो कहानी",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2012, 2023",
      publisher: "Hem Aunty Publications",
      price: "350",
      language: "Hindi-English",
      pdflink: "https://drive.google.com/file/d/1XWXI8AfSzk71K0FQ_OoLtO31OZemNGsT/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "कहानी सुनाने के विविध पक्ष और रोचक शैलियों पर लेखों का संग्रह Essays on story telling",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "142",
      discountedprice: "315",
      image: "/uploads/65.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 66,
      booktype: "general books",
      booknameinenglish: "Sanjhi",
      booknameinhindi: "साँझी",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2014",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1ly1okhJJOVdt-AVTcFR1BrXuAjtLeRfz/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "लोक कला सांझी के बारे में पुस्तक ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "22",
      discountedprice: "135",
      image: "/uploads/66.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 67,
      booktype: "general books",
      booknameinenglish: "Mehndi",
      booknameinhindi: "मेहंदी",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "1998, 2004",
      publisher: "Hem Aunty Publications",
      price: "70",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/15wKBJ3y3O6rVyGp49GaeRsGSFPzjKOc-/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "लोक कला मेहंदी के बारे में पुस्तक ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "63",
      image: "/uploads/67.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 68,
      booktype: "general books",
      booknameinenglish: "Henna",
      booknameinhindi: "",
      writer: "hem Bhatnagar",
      agegroup: "Adults",
      edition: "1998, 2004",
      publisher: "Hem Aunty Publications",
      price: "70",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1D8mUf3q3U9O3XSOBqC5PfWCGdxKopZ1x/view?usp=sharing",
      category: "English, adults, prose",
      description: "Folk art Henna ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "63",
      image: "/uploads/68.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 69,
      booktype: "general books",
      booknameinenglish: "Mukh Mukh Pani, Amrit Pani (Edited)",
      booknameinhindi: "मुख मुख पानी, अमृत पानी (संपादित)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2011",
      publisher: "Hem Aunty Publications",
      price: "200",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1QnpZp0rHSaXIHLTRroBjSCmrsacA3o9q/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "पानी से सम्बंधित लघु नाटिकाएं, लोक गीत आदि  ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "42",
      discountedprice: "180",
      image: "/uploads/69.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 70,
      booktype: "general books",
      booknameinenglish: "Geet kalpdrum",
      booknameinhindi: "गीत कल्पद्रुम",
      writer: "संकलन - संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2018",
      publisher: "Hem Aunty Publications",
      price: "900",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Wphxs-BMdgSQCJw_eJ46KX4ZFJ6elA7t/view?usp=sharing",
      category: "hindi, adults, poetry",
      description: "जीवन पर्यन्त विविध अवसरों पर गए जाने वाले गीतों का संग्रह   ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "274",
      discountedprice: "810",
      image: "/uploads/70.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 71,
      booktype: "general books",
      booknameinenglish: "nyaymurti S.D.Singh",
      booknameinhindi: "न्यायमूर्ति एस डी सिंह ",
      writer: "संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2012",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1EgTuSbgLYpftiNSfSiiViYCvca_47Giy/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "स्वर्गीय जस्टिस एस डी सिंह (संस्मरण) ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "262",
      discountedprice: "450",
      image: "/uploads/71.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 72,
      booktype: "general books",
      booknameinenglish: "Meri Khoj Yatra",
      booknameinhindi: "मेरी खोज यात्रा",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2011",
      publisher: "Hem Aunty Publications",
      price: "200",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1LMI8Z07WHl-HVm6F3mKKeYUU45U2q_NA/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "भारत के पुस्तकालयों में हस्तलिखित ग्रंथों की खोज का यात्रा वृतांत ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "86",
      discountedprice: "180",
      image: "/uploads/72.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 73,
      booktype: "general books",
      booknameinenglish: "Harsingar jharte rahe (Diary)",
      booknameinhindi: "हरसिंगार झरते रहे (डायरी)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2010",
      publisher: "Hem Aunty Publications",
      price: "800",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1wffpghMsG83I0o-P5qdoPnSrfFMP0VmC/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "दैनिक जीवन के बोध, डायरी के पन्नों पर ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "238",
      discountedprice: "720",
      image: "/uploads/73.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 74,
      booktype: "general books",
      booknameinenglish: "Bol Beti Bol",
      booknameinhindi: "बोल बेटी बोल",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "1994, 2009, 2023",
      publisher: "Hem Aunty Publications",
      price: "200",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1esSVWH-qgIxaQGvrCyz0nCRv_V5yclSS/view?usp=sharing",
      category: "hindi, adults, poetry",
      description: "लड़कियों की जागरूकता के लिए सांगीतिक नाटक ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "22",
      discountedprice: "180",
      image: "/uploads/74.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 75,
      booktype: "general books",
      booknameinenglish: "A se ha (Edited) (Hand Written)",
      booknameinhindi: "अ से ह",
      writer: "संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2011, 2023",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1alYyrvzu8T-U9ReMYnwMwST2XGSeDwQj/view?usp=sharing",
      category: "hindi, adults, poetry",
      description: "विविध कवियों की कविताओं से ली गयी पंक्तियों का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "75",
      discountedprice: "450",
      image: "/uploads/75.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 76,
      booktype: "general books",
      booknameinenglish: "Anayas (Edited) H.B",
      booknameinhindi: "अनायास (संपादित) हार्ड कवर",
      writer: "संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2001",
      publisher: "Hem Aunty Publications",
      price: "125",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1E9op36unr3TC-qZPLpkUyzSkfAK3BVf9/view?usp=sharing",
      category: "hindi, adults, poetry",
      description: "सोलह कवियत्रियों की सात सात कविताओं का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "196",
      discountedprice: "112",
      image: "/uploads/76.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 77,
      booktype: "general books",
      booknameinenglish: "Anayas (Edited) P.B.",
      booknameinhindi: "अनायास (संपादित) पेपर बैक",
      writer: "संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2001",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1E9op36unr3TC-qZPLpkUyzSkfAK3BVf9/view?usp=sharing",
      category: "hindi, adults, poetry",
      description: "Collection of poems by 16 women writers",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "196",
      discountedprice: "72",
      image: "/uploads/77.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 78,
      booktype: "general books",
      booknameinenglish: "Ye Aniyare Nain (Edited)",
      booknameinhindi: "ये अनियारेे नैन",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2012, 2023",
      publisher: "Hem Aunty Publications",
      price: "350",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1jRE4RVXPHCEtSGWBbfm08FTarO4T8SYj/view?usp=sharing",
      category: "hindi, adults, poetry",
      description: "नेत्रों से सम्बंधित कुछ सुन्दर साहित्यिक पंक्तियाँ, चित्रों के साथ  ",
      illustrator: "Hem Bhatnagar",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "30",
      discountedprice: "315",
      image: "/uploads/78.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 79,
      booktype: "general books",
      booknameinenglish: "Suno Prithivi (Coloured)",
      booknameinhindi: "सुनो पृथिवी (रंगीन)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2004",
      publisher: "Hem Aunty Publications",
      price: "1250",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1rLPrkOO3I9s39FzHBtDvu5iVxDTtF680/view?usp=sharing",
      category: "hindi, adults, poetry",
      description: "लेखिका द्वारा बनाये रंगीन चित्रों की पृष्ठभूमि में उनकी कवितायेँ ",
      illustrator: "Hem Bhatnagar",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "154",
      discountedprice: "1125",
      image: "/uploads/79.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 80,
      booktype: "general books",
      booknameinenglish: "Gyan Pitari (Collection)",
      booknameinhindi: "ज्ञान पिटारी",
      writer: "संकलन - संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2014",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1v9o6ZDWtaND4caVcX1TzsAWSOtffKVzp/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "विविध विषयों पर रोचक जानकारी - एक प्रकार का लघु कोष ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "350",
      discountedprice: "450",
      image: "/uploads/80.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 81,
      booktype: "general books",
      booknameinenglish: "Sangeet Upchar (Music Therapy)",
      booknameinhindi: "संगीत उपचार",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "400",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1qyMNWY_WhpbnPjJCOIsnyiYhYEfxsSGI/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "संगीत से उपचार पर पुस्तक (प्रयोग के साथ)",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "60",
      discountedprice: "360",
      image: "/uploads/81.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 82,
      booktype: "general books",
      booknameinenglish: "Naari tere roop anek (Edited) (Hand written)",
      booknameinhindi: "नारी तेरे रूप अनेक (हस्तलिखित)",
      writer: "संकलन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2003",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1WgV8cM4er1tj6Evu0Yj361WVOo5CJrz5/view?usp=sharing",
      category: "Hindi, adults, prose",
      description: "नारी सम्बन्धी उद्धरण का संग्रह एक लघु पुस्तिका के रूप में ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "72",
      image: "/uploads/82.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 83,
      booktype: "general books",
      booknameinenglish: "Woman, your facets are many",
      booknameinhindi: "",
      writer: "Compiled by Hem Bhatnagar",
      agegroup: "Adults",
      edition: "2003",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1cM-W8zXOJxuzcOe0He-2AE-NueoTN3Hr/view?usp=sharing",
      category: "English, adults, prose",
      description: "Quotes on women",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "72",
      image: "/uploads/83.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 84,
      booktype: "general books",
      booknameinenglish: "Chameli mehki, gungunai Madhvi",
      booknameinhindi: "चमेली महकी, गुनगुनाई माधवी (हस्तलिखित)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2016",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1z1tPkXX59ZBlpFb7CMfF_-PYurdXkId3/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "दैनिक जीवन में पाए बोध - एक कलात्मक पुस्तक के रूप में ",
      illustrator: "Hem Bhatnagar",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "48",
      discountedprice: "450",
      image: "/uploads/84.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 85,
      booktype: "general books",
      booknameinenglish: "Ganpati Jagvandit",
      booknameinhindi: "गणपति जगवंदित",
      writer: "संकलन - संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2015",
      publisher: "Hem Aunty Publications",
      price: "1500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Yn3Dt_R3ZkQpBKJOEQFQWbxjW9gOd0_q/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "गणेश पूजन का इतिहास और वर्तमान स्वरुप  - एक कलात्मक पुस्तक के रूप में ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose ",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "76",
      discountedprice: "1350",
      image: "/uploads/85.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 86,
      booktype: "general books",
      booknameinenglish: "Muskan aur Malinya (Hand Written)",
      booknameinhindi: "मुस्कान और मालिन्य",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2013",
      publisher: "Hem Aunty Publications",
      price: "600",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1CvQj7WeuNG5MQD9LkVYQFY0PBdgGVVcZ/view?usp=sharing",
      category: "hindi, adults, poetry",
      description: "काव्यात्मक एवं कलात्मक अभिव्यक्ति भावों की ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "60",
      discountedprice: "540",
      image: "/uploads/86.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 87,
      booktype: "general books",
      booknameinenglish: "Hastlikhit Granth",
      booknameinhindi: "हस्तलिखित ग्रंथ",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2017",
      publisher: "Hem Aunty Publications",
      price: "1200",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1KOUIeXhCcDvjmUtfcIJZAOkTY0I6X1hG/view?usp=sharing",
      category: "hindi, adults, prose",
      description: "श्रृंगार युगीन हस्तलिखित ग्रंथों पर शोध ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "544",
      discountedprice: "1080",
      image: "/uploads/87.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 88,
      booktype: "general books",
      booknameinenglish: "Adikaaleen hindi sahitya ki mool chetna",
      booknameinhindi: "आदिकालीन हिंदी साहित्य की मूल चेतना संगीत",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2013",
      publisher: "Hem Aunty Publications",
      price: "350",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1HIMfzoU_CK2V_h3ssihBW-PuCqy5N0lD/view?usp=sharing",
      category: "hindi, Adults, prose ",
      description: "आदिकालीन हिंदी साहित्य पर शोध पुस्तक ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "80",
      discountedprice: "315",
      image: "/uploads/88.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 89,
      booktype: "general books",
      booknameinenglish: "Mangal karan amangal hari",
      booknameinhindi: "मंगल करन, अमंगल हारी",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2013",
      publisher: "Hem Aunty Publications",
      price: "250",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1z65sXu0cMNED0T2Pxl6PuLvnoqfRdAq8/view?usp=sharing",
      category: "hindi, Adults, prose ",
      description: "दैनिक जीवन से उपजी कहानियों का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "80",
      discountedprice: "225",
      image: "/uploads/89.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 90,
      booktype: "general books",
      booknameinenglish: "Ahladini radha (Edited)",
      booknameinhindi: "आह्लादिनी राधा (संपादित)",
      writer: "संकलन - संपादन : हेम भटनागर ",
      agegroup: "Adults",
      edition: "2011",
      publisher: "Hem Aunty Publications",
      price: "400",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1OmtLbEAPPfRgy0V78T7idLRbnJFTssmW/view?usp=sharing",
      category: "Hindi, adults, poetry",
      description: "राधा से सम्बंधित साहित्यिक सन्दर्भ कलात्मक पृष्ठभूमि में ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "360",
      image: "/uploads/90.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 91,
      booktype: "general books",
      booknameinenglish: "Shringar Yug Mein Sangeet Kavya",
      booknameinhindi: "ाृंगार युग में संगीत काव्य",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1142hmpGnukIYNbmTtA4FULKll67N-c_s/view?usp=sharing",
      category: "hindi, Adults, prose ",
      description: "श्रृंगार युगीन हस्तलिखित ग्रंथों पर शोध ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "310",
      discountedprice: "450",
      image: "/uploads/91.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 92,
      booktype: "general books",
      booknameinenglish: "Adhyapan ke anubhav",
      booknameinhindi: "अध्यापन के अनुभव ",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "2005",
      publisher: "Hem Aunty Publications",
      price: "200",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1A2gxVplGOCriqqRywBc4rH2q8zkz1mut/view?usp=sharing",
      category: "hindi, Adults, prose",
      description: "अध्यापन  सम्बंधित अनुभवों पर लेखों का संग्रह ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "38",
      discountedprice: "180",
      image: "/uploads/92.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 93,
      booktype: "general books",
      booknameinenglish: "A to Z (Edited)",
      booknameinhindi: "",
      writer: "Dr. P.S.Bhatnagar",
      agegroup: "Adults",
      edition: "2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "450",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1KnTpNpYuD0WmUfGyDvO8WXRUi3QXx8l6/view?usp=sharing",
      category: "English, adults, prose",
      description: "collection of quotable quotes ",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "94",
      discountedprice: "405",
      image: "/uploads/93.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 94,
      booktype: "general books",
      booknameinenglish: "Tales of Friendship Beyond Words",
      booknameinhindi: "",
      writer: "Madhulika Agarwal",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "400",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1MvzMkwBbJ83K_3LoVVIgnCQdGQJ6p0-i/view?usp=sharing",
      category: "English, children, 8-14 years",
      description: "Collection of nine stories of unusual friendhips and more    ",
      illustrator: "Bindulika Sharma, Asha Agrawal, Awadhesh",
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "96",
      discountedprice: "360",
      image: "/uploads/94.png",
      isActive: "1",
      isfeatured: "1"
    },
    {
      id: 95,
      booktype: "books made by children",
      booknameinenglish: "Dhanyawad Lakdi",
      booknameinhindi: "धन्यवाद लकड़ी ",
      writer: "तेजसी भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2005, 2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1XwtUP_KbPObBD5X6hyV8KpXccNA9IzA3/view?usp=sharing",
      category: "Books made by children",
      description: "क्या हुआ जब लकड़ी की चीज़ें घर से भागने लगीं ? जानने के लिए पढ़िए ",
      illustrator: "तेजसी भटनागर ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "135",
      image: "/uploads/95.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 96,
      booktype: "books made by children",
      booknameinenglish: "Jab chat par bandar kooda",
      booknameinhindi: "जब छत पर बन्दर कूदा ",
      writer: "सारंग भटनागर ",
      agegroup: "2.5-8 years",
      edition: "2005, 2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1DuMFgm0ytWdifC8y_Jy8gJF9qkn3Xx08/view?usp=sharing",
      category: "Books made by children",
      description: "क्या हुआ जब छत पर बन्दर कूदा? जानने के लिए पढ़िए ",
      illustrator: "सारंग भटनागर ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "6",
      discountedprice: "90",
      image: "/uploads/96.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 97,
      booktype: "books made by children",
      booknameinenglish: "Yadi mai phool hoti",
      booknameinhindi: "यदि मैं फूल होती ",
      writer: "रेवली रम्या ",
      agegroup: "2.5-8 years",
      edition: "2005, 2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1q1OhChMI-db7RGTnilutHLLPop1asbW_/view?usp=sharing",
      category: "Books made by children",
      description: "यदि मैं फूल होती तो क्या होता ? जानने के लिए पढ़िए ",
      illustrator: "रेवली रम्या ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "8",
      discountedprice: "90",
      image: "/uploads/97.png",
      isActive: "1",
      isfeatured: "1"
    },
    {
      id: 98,
      booktype: "books made by children",
      booknameinenglish: "The Trees Message",
      booknameinhindi: "",
      writer: "Aarika Jain",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1FMXZhb6GY2hpLGbPtoj5dfaJMn0lXpTX/view?usp=sharing",
      category: "Books made by children",
      description: "Let`s read to know the tree`s message for us.",
      illustrator: "Aarika Jain",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/98.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 99,
      booktype: "books made by children",
      booknameinenglish: "The Pair of Shoes",
      booknameinhindi: "",
      writer: "Adhhira Kaurr Chhabra",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1hR24Xqkio8auTd2lp9kDK5NRnWoBOIlF/view?usp=sharing",
      category: "Books made by children",
      description: "This story is about the adventures of the pair of shoes",
      illustrator: "Adhhira Kaurr Chhabra",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/99.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 100,
      booktype: "books made by children",
      booknameinenglish: "The Magic of the tree",
      booknameinhindi: "",
      writer: "Adwiti Sharma",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1yAXhHZHhoGYRc6JT9X77nV0sofutK3e-/view?usp=sharing",
      category: "Books made by children",
      description: "Want to know the magic of the tree? Read on..",
      illustrator: "Adwiti Sharma",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/100.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 101,
      booktype: "books made by children",
      booknameinenglish: "Hamari Pyari Nandini",
      booknameinhindi: "हमारी प्यारी नंदिनी ",
      writer: "अर्शवीर सिंह छाबड़ा ",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/144Y3LApoGxBvY-21vswwnKgiAarUAf7v/view?usp=sharing",
      category: "Books made by children",
      description: "हमारी प्यारी नंदिनी गाय की कहानी ",
      illustrator: "अर्शवीर सिंह छाबड़ा ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/101.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 102,
      booktype: "books made by children",
      booknameinenglish: "Adventures of young Nani",
      booknameinhindi: "",
      writer: "Arshvir Singh Chhabra",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1L0jKBYJjHnyuON2Q1aPggT2YNaOfnbjO/view?usp=sharing",
      category: "Books made by children",
      description: "This story is about the adventures of my very own grandmother.",
      illustrator: "Arshvir Singh Chhabra",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/102.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 103,
      booktype: "books made by children",
      booknameinenglish: "The Secret Revenge",
      booknameinhindi: "",
      writer: "Myrah Kumar",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1C_4rGOXLtuNfs-rByGStlt9MVmwoxRvT/view?usp=sharing",
      category: "Books made by children",
      description: "Read to find out who takes revenge from whom?",
      illustrator: "Myrah Kumar",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/103.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 104,
      booktype: "books made by children",
      booknameinenglish: "Chirchira hiran",
      booknameinhindi: "चिडचिड़ा हिरण ",
      writer: "अनाया ",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1mB76ARX5QyDfshSbxHPN1ZZoPqs3FTYg/view?usp=sharing",
      category: "Books made by children",
      description: "हिरण चिड़चिड़ा क्यों था ? क्या वह ठीक हुआ? जानने के लिए पढ़िए ",
      illustrator: "अनाया ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/104.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 105,
      booktype: "books made by children",
      booknameinenglish: "Bells Bold Journey",
      booknameinhindi: null,
      writer: "Avni Sadhwani",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1Oe3T3xWX98djgXYdXpmeDdLdWUef2Hs2/view?usp=sharing",
      category: "Books made by children",
      description: "Bella is my cow. This is story of her bold journey.",
      illustrator: "Avni Sadhwani",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/105.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 106,
      booktype: "books made by children",
      booknameinenglish: "Jadu ki kitaab",
      booknameinhindi: "जादू की किताब ",
      writer: "दमूशा ",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1aIugWH1HG3FkIrzSw5vgqt6919swRHW-/view?usp=sharing",
      category: "Books made by children",
      description: "किताब का क्या जादू था ?  जानने के लिए पढ़िए ",
      illustrator: "दमूशा ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/106.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 107,
      booktype: "books made by children",
      booknameinenglish: "Tommy aur uske dost",
      booknameinhindi: "टॉमी और उसके दोस्त ",
      writer: "ख़ुशी तलवार ",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1bP1iiqB3EntaF03xcFWJ4McWchKv63AA/view?usp=sharing",
      category: "Books made by children",
      description: "यह है टॉमी और उसके दोस्त की कहानी ",
      illustrator: "ख़ुशी तलवार ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/107.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 108,
      booktype: "books made by children",
      booknameinenglish: "The Lesson from the tree",
      booknameinhindi: "",
      writer: "Medha Parashar",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1rEoUC2Mvj_r6KpgeUE5gE6X2CZgZj5xm/view?usp=sharing",
      category: "Books made by children",
      description: "What is the lesson from the tree? Let`s find out.",
      illustrator: "Medha Parashar",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/108.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 109,
      booktype: "books made by children",
      booknameinenglish: "Milly in tangles",
      booknameinhindi: "",
      writer: "Medha Parashar",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1ZTuuWs_xpWqENbMD8CCJyiAO9yGIJl_x/view?usp=sharing",
      category: "Books made by children",
      description: "Wonder why Milly is in tangles? Read on..",
      illustrator: "Medha Parashar",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/109.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 110,
      booktype: "books made by children",
      booknameinenglish: "Shikayati milano",
      booknameinhindi: "शिकायती मिलानो ",
      writer: "मुस्कान तलवार ",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Ltgtz_WoRk51VGB9RRz-81AqV-_eiSXX/view?usp=sharing",
      category: "Books made by children",
      description: "मिलानो को किससे क्या शिकायत है? यह कहानी उसी की है ",
      illustrator: "मुस्कान तलवार ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/110.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 111,
      booktype: "books made by children",
      booknameinenglish: "My silver wings",
      booknameinhindi: "",
      writer: "Nayantara Rajaram",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1ORIKxQFLSbJIW8nnk2JVPL-oaA19JO3j/view?usp=sharing",
      category: "Books made by children",
      description: "Where did I go with my silver wings? Read on..",
      illustrator: "Nayantara Rajaram",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/111.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 112,
      booktype: "books made by children",
      booknameinenglish: "Best cow in the world",
      booknameinhindi: "",
      writer: "Netra Kolleri",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1jc1oPD9ZAf3ldrnsHpMDATKUW7aPDIed/view?usp=sharing",
      category: "Books made by children",
      description: "Who is the best cow in the world and why? Let`s find out.",
      illustrator: "Netra Kolleri",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/112.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 113,
      booktype: "books made by children",
      booknameinenglish: "The tree family",
      booknameinhindi: "",
      writer: "Sanmita",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1tqlpuVSz9lqkOJon4n466Td5LkwiBOep/view?usp=sharing",
      category: "Books made by children",
      description: "Read to know about The Tree Family!",
      illustrator: "Sanmita",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/113.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 114,
      booktype: "books made by children",
      booknameinenglish: "The Slipper Twins",
      booknameinhindi: "",
      writer: "Srestha Lenka",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1YOFrAgrPpYVpnqO3muVj_AUQH6o44sp8/view?usp=sharing",
      category: "Books made by children",
      description: "This is the story of the journey of the slipper twins.",
      illustrator: "Srestha Lenka",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/114.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 115,
      booktype: "books made by children",
      booknameinenglish: "The Drama Queen",
      booknameinhindi: "",
      writer: "Srestha Lenka",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/18aG_I9HzySlMDNYTJXgD6bsle9Qrcuuo/view?usp=sharing",
      category: "Books made by children",
      description: "She surely is `The Drama Queen` Read to know why.",
      illustrator: "Srestha Lenka",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/115.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 116,
      booktype: "books made by children",
      booknameinenglish: "Down the memory lane",
      booknameinhindi: "",
      writer: "Srestha Lenka",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1eu8_IMBoMPIeqcz8iVYTb0J2uR1TBXdR/view?usp=sharing",
      category: "Books made by children",
      description: "In this book Srestha`s grandmother goes down the memory lane with her grand daughter.. ",
      illustrator: "Srestha Lenka",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "90",
      image: "/uploads/116.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 117,
      booktype: "books made by children",
      booknameinenglish: "Step into our adventure",
      booknameinhindi: "",
      writer: "Shanaya Kapoor",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1x9KAj-Kbzsx2VlQ09eccz3pjx1-6VIn5/view?usp=sharing",
      category: "Books made by children",
      description: "Adventures are fun. Let`s step into them..",
      illustrator: "Shanaya Kapoor",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/117.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 118,
      booktype: "books made by children",
      booknameinenglish: "Cutting through the chaos",
      booknameinhindi: "",
      writer: "Srishti Sadhwani",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/12DhcDptH5oYGtCKTY8HkIkRXaaIUmRum/view?usp=sharing",
      category: "Books made by children",
      description: "What is the chaos Srishti cuts through? Read on.",
      illustrator: "Srishti Sadhwani",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/118.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 119,
      booktype: "books made by children",
      booknameinenglish: "Jadui pustak",
      booknameinhindi: "जादुई पुस्तक ",
      writer: "सुहाना ",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1HWZZdGoVtr-P7YuCPHvt6xciPrTdU3Ga/view?usp=sharing",
      category: "Books made by children",
      description: "किताब का क्या जादू था ?  जानने के लिए पढ़िए ",
      illustrator: "सुहाना ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/119.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 120,
      booktype: "books made by children",
      booknameinenglish: "Jadui kitab",
      booknameinhindi: "जादुई किताब ",
      writer: "विहान मल्होत्रा ",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Z5Il3Wiuh0IoIkHV6kaZZ1BS3KUk2uhN/view?usp=sharing",
      category: "Books made by children",
      description: "किताब का क्या जादू था ?  जानने के लिए पढ़िए ",
      illustrator: "विहान मल्होत्रा ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/120.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 121,
      booktype: "books made by children",
      booknameinenglish: "The Magic pencil",
      booknameinhindi: "",
      writer: "Vyana vasa",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1rVSyUknxpaIraMyfvNgp_FezBBK5VHpV/view?usp=sharing",
      category: "Books made by children",
      description: "Read to find out about the magic of the pencil.",
      illustrator: "Vyana vasa",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/121.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 122,
      booktype: "books made by children",
      booknameinenglish: "Two goody shoes",
      booknameinhindi: "",
      writer: "Venya kapoor",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1JVGUa69aZqlMEtY1IEpsYugYsdK9JNRX/view?usp=sharing",
      category: "Books made by children",
      description: "Are the Two Goody Shoes too good? Read to find out.",
      illustrator: "Venya kapoor",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/122.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 123,
      booktype: "books made by children",
      booknameinenglish: "Chanchal Chanda",
      booknameinhindi: "चंचल चंदा ",
      writer: "रिवान कुमार ",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1lYV2jKe2KX3V2LgkD2zS1BLTnxBrf0P6/view?usp=sharing",
      category: "Books made by children",
      description: "यह है चंचल चंदा की सुन्दर कहानी ",
      illustrator: "रिवान कुमार ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/123.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 124,
      booktype: "books made by children",
      booknameinenglish: "My silver wings",
      booknameinhindi: "",
      writer: "Vijayini",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1pfA5O82D52mBLfyKMHvqrTZVkt_N2ibA/view?usp=sharing",
      category: "Books made by children",
      description: "Where did I go with my silver wings? Read on..",
      illustrator: "Vijayini",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/124.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 125,
      booktype: "books made by children",
      booknameinenglish: "Runaway wood",
      booknameinhindi: "",
      writer: "Twisha",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1doOERqoMSO2OwSh5fhBESMyR_ap0lE1A/view?usp=sharing",
      category: "Books made by children",
      description: "Where did the Runaway Wood run away? Let`s find out.",
      illustrator: "Twisha",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/125.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 126,
      booktype: "books made by children",
      booknameinenglish: "Dhanyawad Lakdi",
      booknameinhindi: "धन्यवाद लकड़ी ",
      writer: "सुकीर्ति ",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1DFL-OT853eRDZebp2otbI22kmatBk9qW/view?usp=sharing",
      category: "Books made by children",
      description: "क्या हुआ जब लकड़ी की चीज़ें घर से भागने लगीं ",
      illustrator: "सुकीर्ति ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/126.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 127,
      booktype: "books made by children",
      booknameinenglish: "Soniya tanya",
      booknameinhindi: "सोनिया तान्या ",
      writer: "जासमीन ",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1nICCL2Y1fwP6V04YUNoOU1JCztJgwDxa/view?usp=sharing",
      category: "Books made by children",
      description: "सोनिया तान्या दो चप्पल बहनों की कहानी ",
      illustrator: "जासमीन ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/127.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 128,
      booktype: "books made by children",
      booknameinenglish: "Pyare sitare",
      booknameinhindi: "प्यारे सितारे ",
      writer: "अविका ",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/11ux-KfC4dYS12o5A1PRHivofMb_ki7ER/view?usp=sharing",
      category: "Books made by children",
      description: "आकाश में चमकते प्यारे सितारों की बात है इस कहानी में ",
      illustrator: "अविका ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/128.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 129,
      booktype: "books made by children",
      booknameinenglish: "The New Car",
      booknameinhindi: "",
      writer: "Anahat",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1BBI0TmYAbKWF3toHl7PBW6G0eD-Z3fKd/view?usp=sharing",
      category: "Books made by children",
      description: "Let`s read to know about Anahat`s New Car.",
      illustrator: "Anahat",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/129.png",
      isActive: "1",
      isfeatured: "1"
    },
    {
      id: 130,
      booktype: "books made by children",
      booknameinenglish: "taro ka thaila",
      booknameinhindi: "तारों का थैला ",
      writer: "अद्विका ",
      agegroup: "2.5-8 years",
      edition: "2022",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/11kbNnzhuTMbw2fQ0bKQSL_RnEC9s6LYP/view?usp=sharing",
      category: "Books made by children",
      description: "अद्विका ने अपने थैले के तारों का क्या किया ? पढ़कर जानते हैं ",
      illustrator: "अद्विका ",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/130.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 131,
      booktype: "books made by children",
      booknameinenglish: "Topsy turvy",
      booknameinhindi: "",
      writer: "Aarya",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1Ut3bHMs9Z8lxiSQ64F_QOcHLMh1KhEH-/view?usp=sharing",
      category: "Books made by children",
      description: "What chaos has Aarya written about? Let us read and find out.",
      illustrator: "Aarya",
      typeofproduct: null,
      purpose: null,
      genre: "Story",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/131.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 132,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Manna Ki Varnmala",
      booknameinhindi: "मन्ना की वर्णमाला ",
      writer: "कुंकुम जोशी ",
      agegroup: "2.5 - 10 years",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/16MH2AlyfxuhP_PRQeiCb8217XqvESfZ6/view?usp=sharing",
      category: "Hindi-children-educational games-2.5-8 years",
      description: "बच्चों को कविता के साथ हिंदी के अक्षर सीखने के लिए फ़्लैश कार्ड्स ",
      illustrator: "नित्या पंत ",
      typeofproduct: null,
      purpose: "Teaching alphabets",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "26 flash cards",
      discountedprice: "450",
      image: "/uploads/132.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 133,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Hindi Ke Akshar",
      booknameinhindi: "हिंदी के अक्षर ",
      writer: "हेम भटनागर ",
      agegroup: "2.5 - 10 years",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "500",
      language: "Hindi-English",
      pdflink: "https://drive.google.com/file/d/1A2ZGd6OIKIHJsgLX0y1MixlSbgLFmb_l/view?usp=sharing",
      category: "Hindi-children-educational games-2.5-8 years",
      description: "अंग्रेजी जानने बच्चों को हिंदी सीखने के लिए फ़्लैश कार्ड्स ",
      illustrator: "हेम भटनागर ",
      typeofproduct: null,
      purpose: "Literacy - Teaching Hindi language",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "26 flash cards",
      discountedprice: "450",
      image: "/uploads/133.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 134,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Pratibha Pradarshan",
      booknameinhindi: "प्रतिभा प्रदर्शन ",
      writer: "हेम भटनागर ",
      agegroup: "2.5 - 10 years",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "400",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1jiBYguontI0ZnUshrVp8mi97-XB8Chd-/view?usp=sharing",
      category: "Hindi-children-educational games-2.5-8 years",
      description: "बच्चों के खेलने के लिए खेल जिसके माध्यम से प्रतिभा प्रदर्शन भी हो ",
      illustrator: "हेम भटनागर ",
      typeofproduct: null,
      purpose: "Fun educational game",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "15 flash cards",
      discountedprice: "360",
      image: "/uploads/134.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 135,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Mere Pratham charan",
      booknameinhindi: "मेरे प्रथम चरण",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "300",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1TLzF6ID4Ns_dZBKmjzFz6aBwoiu-NjDp/view?usp=sharing",
      category: "Books for special occasions",
      description: "Baby record book",
      illustrator: "हेम भटनागर ",
      typeofproduct: null,
      purpose: "Baby Record Book",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "20",
      discountedprice: "270",
      image: "/uploads/135.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 136,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "My First Step",
      booknameinhindi: "My first steps",
      writer: "Hem Bhatnagar",
      agegroup: "Adults",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "300",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1moFrVClG7aDbuT53Z7YNBSeIVzhr8ZxE/view?usp=sharing",
      category: "English, adults, prose",
      description: "Baby record book",
      illustrator: "Hem Bhatnagar",
      typeofproduct: null,
      purpose: "Baby record book",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "20",
      discountedprice: "270",
      image: "/uploads/136.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 137,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Naval Banra",
      booknameinhindi: "नवल बनड़ा (हस्तलिखित)",
      writer: "हेम भटनागर ",
      agegroup: "Adults",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "900",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1SIriVKBilMDehNtlhCiZMVbV_d_6zWQF/view?usp=sharing",
      category: "Books for special occasions",
      description: "विवाह पर वर को उपहार में देने की पुस्तक ",
      illustrator: "हेम भटनागर ",
      typeofproduct: null,
      purpose: "Gifting",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "20",
      discountedprice: "810",
      image: "/uploads/137.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 138,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Achal Hohi Ahivaat",
      booknameinhindi: "अचल होहि अहिवात तुम्हारा (हस्तलिखित)",
      writer: "Hem Bhatnagar",
      agegroup: "Adults",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "600",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1_r3XrsNy5XLoW3rYCmpped11tnsMu7tn/view?usp=sharing",
      category: "Books for special occasions",
      description: "विवाह पर वधु को उपहार में देने की पुस्तक ",
      illustrator: "हेम भटनागर ",
      typeofproduct: null,
      purpose: "Gifting",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "20",
      discountedprice: "540",
      image: "/uploads/138.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 139,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Vividh Poster",
      booknameinhindi: "विविध पोस्टर ",
      writer: "Hem Bhatnagar",
      agegroup: "Adults",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "100 each",
      language: "Hindi-English",
      pdflink: "https://drive.google.com/file/d/1F6kLKsTzF7v6vDMGq296htWuHaQaqSpJ/view?usp=sharing",
      category: "literary posters for gifting",
      description: "Posters",
      illustrator: "",
      typeofproduct: null,
      purpose: "Gifting",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "",
      discountedprice: "90",
      image: "/uploads/139.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 140,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Vividh Poster",
      booknameinhindi: "विविध पोस्टर ",
      writer: "Hem Bhatnagar",
      agegroup: "Everyone",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "100 each",
      language: "Hindi-English",
      pdflink: "https://drive.google.com/file/d/1KS3HjxbUZaZIw1TtWpJckibiSjUbUB6T/view?usp=sharing",
      category: "literary posters for gifting",
      description: "Posters",
      illustrator: "",
      typeofproduct: null,
      purpose: "Gifting",
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "",
      discountedprice: "90",
      image: "/uploads/140.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 141,
      booktype: "Books for friends and students",
      booknameinenglish: "Vaaniswarupa Vaidushyamani Shashiprabha",
      booknameinhindi: "वाणीस्वरूपा वैदुष्यमणि शशिप्रभा",
      writer: "Editor : Dr. Hem Bhatnagar",
      agegroup: "Adults",
      edition: "2017",
      publisher: "Nihshreyas, Copublisher : Samskar",
      price: "-",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/17o8ag48HnBqovBCmsifH9jDTuHKpgL_-/view?usp=sharing",
      category: "Books for friends and students",
      description: "Memoir : Dr. Shashiprabha Kumar",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Memoir : Dr. Shashiprabha Kumar",
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/141.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 142,
      booktype: "Books for friends and students",
      booknameinenglish: "Devkanya Priya (Edited)",
      booknameinhindi: "देवकन्या प्रिय (संपादित)",
      writer: "Editor : Dr. Hem Bhatnagar",
      agegroup: "Adults",
      edition: "2017",
      publisher: "Nihshreyas, Copublisher : Samskar",
      price: "-",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1rhSo8lE36paWfQQ16g8RogO87lXa54ZI/view?usp=sharing",
      category: "Books for friends and students",
      description: "Memoir : Devkanya Aarya on her 80th",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Memoir : Devkanya Aarya on her 80th",
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/142.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 143,
      booktype: "Books for friends and students",
      booknameinenglish: "Yahan Purvai Bund hai (Edited)",
      booknameinhindi: "यहाँ पुरवाई बंद है",
      writer: "Editor : Dr. Hem Bhatnagar",
      agegroup: "Adults",
      edition: "2007",
      publisher: "Hem Aunty Publications",
      price: "-",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1dP_YwNA6JGaa_AZrv0-_c9cS7TbA4rDX/view?usp=sharing",
      category: "Books for friends and students",
      description: "Memoir : Abha Pandey",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Memoir : Abha Pandey",
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/143.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 146,
      booktype: "Books for friends and students",
      booknameinenglish: "Naina Antari Aancharu (Edited)",
      booknameinhindi: "नैना अंतरि आचरूँ",
      writer: "Editor : Dr. Hem Bhatnagar",
      agegroup: "Adults",
      edition: "2011",
      publisher: "Hem Aunty Publications",
      price: "-",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1hh7QnX9l6M3a6zzPi2zsUrZWT9AZOnYg/view?usp=sharing",
      category: "Books for friends and students",
      description: "Memoir - Preeti Pancholi",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Memoir - Preeti Pancholi",
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/146.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 147,
      booktype: "Books for friends and students",
      booknameinenglish: "O meri amritputriyon",
      booknameinhindi: "ओ मेरी अमृत पुत्रियों",
      writer: "Hem Bhatnagar",
      agegroup: "Adults",
      edition: "2009",
      publisher: "Hem Aunty Publications",
      price: "-",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1ayey33ItzBaBGVG0UUHPn7dHuhHA1flB/view?usp=sharing",
      category: "Books for friends and students",
      description: "Memoir - Students",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Memoir - Students",
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/147.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 148,
      booktype: "Books for friends and students",
      booknameinenglish: "Janki Devi memorial College book",
      booknameinhindi: "जानकी देवी मेमोरियल कॉलेज ",
      writer: "Editor : Dr. Hem Bhatnagar",
      agegroup: "Adults",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "-",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1wC7eW8KRObSJuMT_SL5PK4hxsN5wEKOT/view?usp=sharing",
      category: "Books for friends and students",
      description: "Memoir - JDM College",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Memoir - JDM College",
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/148.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 149,
      booktype: "Books for friends and students",
      booknameinenglish: "Aarshvachan",
      booknameinhindi: "आर्षवचन ",
      writer: "Editor : Dr. Hem Bhatnagar",
      agegroup: "Adults",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "-",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1v3AIuarAqjvsvgQuosaqCp6PTWms8sdT/view?usp=sharing",
      category: "Books for friends and students",
      description: "Special Letters and photographs",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "Special Letters and photographs",
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/149.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 150,
      booktype: "Books for family ",
      booknameinenglish: "More Betva Bhaye Hain Pachas",
      booknameinhindi: "मोरे बेटवा भए हैं पचासा ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1-CNVDuyC-IzR4UmFJRiwEnK-dH7Anq3Y/view?usp=sharing",
      category: "Books for family",
      description: "Amit turns 50",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Amit turns 50",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/150.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 151,
      booktype: "Books for family ",
      booknameinenglish: "Arunmayi Aabha",
      booknameinhindi: "अरुणमयी आभा ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1qbcUQnyYQ72IYkcaaQy8KzrYKrs_PwmX/view?usp=sharing",
      category: "Books for family",
      description: "Abha turns 60",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Abha turns 60",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/151.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 152,
      booktype: "Books for family ",
      booknameinenglish: "Aalhaad Pradayini Aasha",
      booknameinhindi: "आह्लाद प्रदायिनी आशा ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1HMTT4OBcre4K-DiS1I9p3hPcAVhMdi3M/view?usp=sharing",
      category: "Books for family",
      description: "Asha turns 80",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Asha turns 80",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/152.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 153,
      booktype: "Books for family ",
      booknameinenglish: "Sanjay",
      booknameinhindi: "संजय ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1HQ6vRKY4ljX8BMDy9MJc2O0uBTO0_qWC/view?usp=sharing",
      category: "Books for family",
      description: "Sanjay turns 60",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Sanjay turns 60",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/153.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 154,
      booktype: "Books for family ",
      booknameinenglish: "Deepak",
      booknameinhindi: "दीपक ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1rcgxNMSEyuWOM_w0sq474rtH06slAd9z/view?usp=sharing",
      category: "Books for family",
      description: "Deepak turns 70",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Deepak turns 70",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/154.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 155,
      booktype: "Books for family ",
      booknameinenglish: "Gayiye Ganpanti Jag Vandan",
      booknameinhindi: "गाइये गणपति जग वंदन  ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1akwOeU8gi3pwNtQ9_thUFdPNTlxluOoZ/view?usp=sharing",
      category: "Books for family",
      description: "Dhooli Weds Pat",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Dhooli Weds Pat",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/155.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 156,
      booktype: "Books for family ",
      booknameinenglish: " Shyamal Kishor Gauri Radhika Sung",
      booknameinhindi: "श्यामल किशोर गोरी राधिका संग ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1DoY3h9RA9UyNTuR75Pgj3mjo9wiTiKTJ/view?usp=sharing",
      category: "Books for family",
      description: "Dinesh Vandana 50th Anniversary",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Dinesh Vandana 50th Anniversary",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/156.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 157,
      booktype: "Books for family ",
      booknameinenglish: "Gaurav",
      booknameinhindi: "गौरव",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/11kXArPVhbBaxGMmeaYv1i4OBnT2bRbuT/view?usp=sharing",
      category: "Books for family",
      description: "Gaurav 50",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Gaurav 50",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/157.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 158,
      booktype: "Books for family ",
      booknameinenglish: "Aaye Hain To Jayege",
      booknameinhindi: "आये हैं तो जायेंगे ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1YfEwJ5hA0j8IyyGnEcrmtMP90aRxsq-y/view?usp=sharing",
      category: "Books for family",
      description: "Remembering Kusum Bhatnagar",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Remembering Kusum Bhatnagar",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/158.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 159,
      booktype: "Books for family ",
      booknameinenglish: "Jiji",
      booknameinhindi: "जीजी ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/10ygogwnUVPDOeglDGFf-VVADqRdQyA6z/view?usp=sharing",
      category: "Books for family",
      description: "Kusum Bhatnagar 90",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Kusum Bhatnagar 90",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/159.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 160,
      booktype: "Books for family ",
      booknameinenglish: "Swarn Badhava",
      booknameinhindi: "स्वर्ण बधावा ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1bN9YyKFTFuSLzK1w8qmYqRI7tHZGxmQl/view?usp=sharing",
      category: "Books for family",
      description: "Omi Manju 50th Anniversary",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Omi Manju 50th Anniversary",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/160.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 161,
      booktype: "Books for family ",
      booknameinenglish: "Sambandho ki Mahak",
      booknameinhindi: "संबंधों की महक ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1jwDyBrwf-_JqFSWaZERt1uQZ0ai0mzO1/view?usp=sharing",
      category: "Books for family",
      description: "Family letters and photographs",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Family`s letters and photographs",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/161.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 162,
      booktype: "Books for family ",
      booknameinenglish: "Maa",
      booknameinhindi: "माँ ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1CRPs5ksIt5FPbusPpy_ql1jzgdsOfY5U/view?usp=sharing",
      category: "Books for family",
      description: "Poster for mother",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Poster for mother",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/162.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 163,
      booktype: "Books for family ",
      booknameinenglish: "Neh Bhari Sneh",
      booknameinhindi: "नेह भरी स्नेह ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1KgJoBhx_7CG_n4KKLa1XX28WCsljckt7/view?usp=sharing",
      category: "Books for family",
      description: "Sneh turns 90",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Sneh turns 90",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/163.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 164,
      booktype: "Books for family ",
      booknameinenglish: "Sat Chitt Aanand Swaroop",
      booknameinhindi: "सत् चित्त आनंद स्वरूप ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1XVC3GUT3vmxRf_hfX6mIDNYvAuDIKGRT/view?usp=sharing",
      category: "Books for family",
      description: "SSB 89",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "SSB 89",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/164.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 165,
      booktype: "Books for family ",
      booknameinenglish: "Pyari Tejasi",
      booknameinhindi: "प्यारी तेजसी ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1HU2uTQzwNwvDAUZTz4rQ5vpvg_M3mJ7o/view?usp=sharing",
      category: "Books for family",
      description: "Tejasi`s birth",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Tejasi`s birth",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/165.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 166,
      booktype: "Books for family ",
      booknameinenglish: "Nyota Biyah Ka",
      booknameinhindi: "न्योता ब्याह का ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1uxXHNQJg_ZqalVRC7sLvBBaw5VlPBmsY/view?usp=sharing",
      category: "Books for family",
      description: "Unmukt-Nupur wedding invite",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "Unmukt-Nupur wedding invite",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/166.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 167,
      booktype: "Books for family ",
      booknameinenglish: "Vishnu Sudha",
      booknameinhindi: "विष्णु सुधा ",
      writer: "Hem Bhatnagar",
      agegroup: "",
      edition: "",
      publisher: "Hem Aunty Publications",
      price: "",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1x0GchpJq-uI21dqg-7JtV0vDOuzJ7Rn7/view?usp=sharing",
      category: "Books for family",
      description: "In memory of Vishnu-Sudha",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: "In memory of Vishnu-Sudha",
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: "0",
      image: "/uploads/167.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 171,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1BtfrArqQDKCYha-zRYJHxnrUf_J3JMQd/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "बारामासा स्त्री लेखिका 2002",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: "Calendar 2002",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/171.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 172,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/17o8PH-cR4WrNNXQqcIqCqpEErfoilSTh/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "बारामासा लोक संस्कृति 2003",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: "Calendar 2003",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/172.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 173,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1s1-DiCRqnLn40GChiYI8naPdli783wSB/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "बारामासा डाक टिकट 2004",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: "Calendar 2004",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/173.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 174,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1TSs1X8Bg_mPEF2Vr7Hcdiq3XequyoQp2/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "बारामासा स्वयंभुवः 2005",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: "Calendar 2005",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/174.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 175,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1Z6Uk_6Q9KI0B0nQ8EiDGIevTwEnJUtjg/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "बारामासा कबीर 2006",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: "Calendar 2006",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/175.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 176,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1f5WMmJlmQMvR1vwbc6NAbEkktuvwNMrs/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "बारामासा संदेशों का",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: "Calendar 2008",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/176.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 177,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1BvSxMbT2717OrJ3Ufd0rRx444M6_T2e_/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "बारामासा खेल 2010",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: "Calendar 2010",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/177.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 178,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1cUb_D6MGDJ_t1swaqt6c8sF4_k4-ghek/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "आह्लादिनी शक्ति राधा बारामासा",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: "Calendar 2011",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/178.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 179,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1mZaNsuMIYP5fX_Zt-pF2IiDV8ztSdLVt/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "चाँद बारामासा",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2012",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/179.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 180,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1s64nYLkdzI8F7lpGpRkQBmVpnKKoKmzO/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "बारामासा हास्य व्यंग 2013",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2013",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/180.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 181,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1b011uwIM4vP72A9zuGUEeAt3gvg8kr6W/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "पत्रकारिता बारामासा",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2014",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/181.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 182,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1Xc7ZRSVBUBMRowf_92Htp8LWA8_MgDOC/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "पक्षी बारामासा",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2015",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/182.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 183,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/15EbP-Bpj8JBB3xkrbbnL93E81fc8o4Uh/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "वृक्ष बारामासा",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2016",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/183.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 184,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1d8e_Ome7PWU4VvDSjSgOB6dqxahR7dkc/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "श्रृंगार बारामासा",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2017",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/184.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 185,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1z7SkizROc_8_fZUuzyk4YsTLdRI8Sq7G/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "फूल और शूल बारामासा",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2018",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/185.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 186,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1AnqcZAwuZjq2-7CmNiV06DUDdkQjfZKr/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "क्षिति जल पावक गगन समीरा बारामासा",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2019",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/186.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 187,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1tvgIepVPQ2ly3lKCX-RQRXwddZ-m29Rg/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "हेम भटनागर के चित्रों की पृष्ठभूमि में उनका कथ्य",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Calendar 2020",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/187.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 188,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1lRD4cUusaJpqhO0sg2urJbA9S0K6nt_x/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2003",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2003",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/188.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 189,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1elLMR5-Of3I_MJBJ9bFS36JFayyrwqEe/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2004 अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2004 april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/189.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 190,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1Mic3i8S6HIL56N9t6-A7nWijdgSBV1LM/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2004 सितम्बर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2004 september",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/190.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 191,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1WAhHChEcxUoBJ_4pwmlRgHzSntETvvRh/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2005 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2005 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/191.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 192,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1FYKMie58SuoR9atFlszks-9iTumBfUS7/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2005 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2005 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/192.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 193,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1_jIEl9cqW7M5dZy89mWBa-YX36kFdYav/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2005 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2005 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/193.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 194,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/11XcptiIGzFqc3TWHPjc0RsieQdR3V0bE/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2006 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2006 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/194.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 195,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1G811BQkSeZps8JKQUcgiMnDjjU9Qynnl/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2006 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2006 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/195.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 196,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1vEwkjoNADftjhw24fwefTrzl4z3WgEbx/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2006 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2006 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/196.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 197,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1yhkD_2FUDF7_pzxj8lMplJeX9b5FLpVN/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2007 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2007 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/197.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 198,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1UcwRiHOx8fpRGlbKHoKdt6_4f2I3WQQI/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2007 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2007 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/198.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 199,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/13riWf_ekTe45z0KwS5WLM_hfhHLq2mAV/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2008 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2008 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/199.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 200,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1m6mzsVhUMsKZ3EppSoJysvIZLXogQi4t/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2008 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2008 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/200.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 201,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1Baj9anY8Tz-GEoWNn6jefFI1DqnTDT8h/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2008 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2008 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/201.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 202,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1-3vC2l2f9eTfmF62OhQWBVoSaUZOOdkp/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2009 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2009 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/202.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 203,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1Q_v0lzwMLQN2mWbaHHusFscZoEf5uiq1/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2009 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2009 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/203.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 204,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1XuPU3_DytPGN-G1kAoZxlHwvWJK7fytS/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2009 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2009 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/204.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 205,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1vFedNp58HO-0-wCXpl0mpkQ9yutzakqO/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2010 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2010 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/205.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 206,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1hfccbjz1n1wn-OkpTBf44Ts03-0V5-Np/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2010 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2010 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/206.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 207,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/18sBI66w304CTt_xbIHY4gZUkk_kKcO_R/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2010 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2010 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/207.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 208,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1f74ivjjq85mVHvtlUOGfJh5Vm4_R0X2U/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2011 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2011 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/208.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 209,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1XwN_co3PkxA6oc5ZxkxHiYPG4fCrHCox/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2011 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2011 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/209.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 210,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1IKFs5VPBH22qY1GQhuNpJTEy_RDfe2eJ/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2011 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2011 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/210.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 211,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1qvT-3ctknWln1hsXz1L3gN79y_ofW7Wp/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2012 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2012 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/211.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 212,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1i8XptXQOOfs4z_2Wanx37N35kGT2oENQ/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2012 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2012 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/212.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 213,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1-UTJXtnoO2NsDp1i2hHWDLj9rYjcvFPj/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2012 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2012 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/213.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 214,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1u4NGc6S6ROltyMfx_KfGi9o0reO_fimZ/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2013 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2013 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/214.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 215,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/13qDzt5fS9MbRWbAK_IZv22he-wQYnJfR/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2013 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2013 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/215.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 216,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1s1HODKxxQrvDet_dT6No4CA0bOwEQZYf/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2013 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2013 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/216.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 217,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1AneUYjLfB-Sy1NbDCxUpYevNZ5IhubCo/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2014 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2014 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/217.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 218,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/16tcp5UBtcyUP5o5i5WKkveeg63rTuBUE/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2014 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2014 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/218.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 219,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1TXzR16flyVWCHa0B73_cVhtADT8ZU6EI/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2014 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2014 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/219.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 220,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1mOSbQZnFqn-6Db2DATn_EPZ6u0VJGHCT/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2015 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2015 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/220.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 221,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1HPna5jIUKftwSx9En-U3c8otKy25kHp-/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2015 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2015 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/221.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 222,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1bY1jyvaQ6_UGgIDIxYKrbadGe-U7R8LE/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2015 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2015 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/222.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 223,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1qLW7j7ff_MsM0ARhjtFpIb5kTYHZDZzP/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2016 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2016 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/223.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 224,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1wWRQ3_qHMHLKoh_6G2MrM9zGOfw8RuVW/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2016 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2016 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/224.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 225,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1Xk73Dz2XHccOu72DVToHJbQ4xd2N0SO7/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2016 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2016 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/225.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 226,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1r41cKP-72LYp_sbXJHQsq-fblc3fk5ms/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2017 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2017 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/226.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 227,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1awOdl7biXdTrQAGytBbPD5p_puSiTz5x/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2017 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2017 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/227.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 228,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1MiGQuYk6o0Z17tplVrdS8dgcTE18ve74/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2017 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2017 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/228.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 229,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/17z1qakapbLEHcRopZx75-JbAXaHchv6A/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2018 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2018 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/229.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 230,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1cF-TEqKqotorZziR4JawYmHCT7Fl1vNC/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2018 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2018 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/230.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 231,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1nc-vgmhSt9GHYxRuOkVRJmxhGZQFzGya/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2018 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2018 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/231.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 232,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1FI-4PqRsDrPZvluiR6zLOMsWJ-t65yxy/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2019 जनवरी अप्रैल",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2019 jan april",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/232.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 233,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1yUE_CtAuampt4Kh9vox8mulwlFRmSmtq/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2019 मई अगस्त",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2019 may august",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/233.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 234,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1m0b7BPyhj9iHaepetdwT2MbBB_uq4JCu/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "कलरव 2019 सितम्बर - दिसंबर",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Magazine 2019 september december",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/234.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 235,
      booktype: "Literary calendars and magazine",
      booknameinenglish: "",
      booknameinhindi: "",
      writer: "",
      agegroup: "",
      edition: "",
      publisher: "",
      price: "",
      language: "",
      pdflink: "https://drive.google.com/file/d/1W4dVbP_lFEHeM2EJwz6d7qUUwIJjMgIN/view?usp=sharing",
      category: "Literary calendars and magazine",
      description: "वनिता रजत जयंती अंक 2009",
      illustrator: "",
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: "",
      product: "Vanita patrika silver jubilee edition 2009",
      noofpages: null,
      discountedprice: null,
      image: "/uploads/235.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 236,
      booktype: "AudioVideo",
      booknameinenglish: "Bol Beti Bol",
      booknameinhindi: "बोल बेटी बोल",
      writer: "Hem Bhatnagar",
      agegroup: "Adults",
      edition: "1994, 2009, 2023",
      publisher: "Hem Aunty Publications",
      price: "200",
      language: "Hindi",
      // pdflink: "https://drive.google.com/file/d/1esSVWH-qgIxaQGvrCyz0nCRv_V5yclSS/view?usp=sharing",
      pdflink:'https://bookapi.hemaunty.org/books/bolbetibol.pdf',
      category: "hindi, adults, poetry",
      description: "लड़कियों की जागरूकता के लिए सांगीतिक नाटक ",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "22",
      discountedprice: "180",
      image: "/uploads/236.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 237,
      booktype: "general books",
      booknameinenglish: "The Tiny Ant",
      booknameinhindi: null,
      writer: "Hem Bhatnagar",
      agegroup: "2.5-8 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1GC__NQQnyuKb_CPk8SUGyRLeYjrKz2rb/view?usp=sharing",
      category: "English, children, 2.5-8",
      description: "What did the children learn from the ant in Grand Ma's kitchen? Let's find out from this poetic story.",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: "Poetry",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "90",
      image: "/uploads/237.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 238,
      booktype: "general books",
      booknameinenglish: "The Magical Bindi",
      booknameinhindi: null,
      writer: "Hem Bhatnagar",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "80",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1_P_1wn0K0QN2FItVgLfhdS9_p5Ofx-3O/view?usp=sharing",
      category: "English, children, prose",
      description: "A musical Story of Revali Ramya's Magical Bindi.",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "72",
      image: "/uploads/238.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 239,
      booktype: "general books",
      booknameinenglish: "Pyari Betiyon",
      booknameinhindi: "प्यारी बेटियॉं",
      writer: "Hem Bhatnagar",
      agegroup: "8-14 years",
      edition: "2002, 2023",
      publisher: "Hem Aunty Publications",
      price: "50",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1CjJIDc3qVm3fCP8ZXw3XAFfoj3hGgpju/view?usp=sharing",
      category: "Hindi, children, 8-14 years",
      description: "आज की किशोरी बच्चियों से बात",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "45",
      image: "/uploads/239.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 240,
      booktype: "general books",
      booknameinenglish: "Dear Daughters",
      booknameinhindi: "",
      writer: "Hem Bhatnagar",
      agegroup: "8-14 years",
      edition: "2023",
      publisher: "Hem Aunty Publications",
      price: "50",
      language: "English",
      pdflink: "https://drive.google.com/file/d/1wLWoPwG2yIv9kqplb0LYpDPD_lKNgyip/view?usp=sharing",
      category: "English, children, 8-14 years",
      description: "A conversation with adolescent girls of today.",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: "",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "10",
      discountedprice: "45",
      image: "/uploads/240.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 241,
      booktype: "general books",
      booknameinenglish: "What do the birds say?",
      booknameinhindi: "चिड़ियाँ क्या बोलीं ?",
      writer: "Hem Bhatnagar",
      agegroup: "8-14 years",
      edition: "2013, 2023",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi-English",
      pdflink: "https://drive.google.com/file/d/1wx1CBPPYZLuqD6FMFeJhyk950wPsMdCV/view?usp=sharing",
      category: "Hindi, children, 8-14 years",
      description: "आइये, जानते हैं कि चिड़ियाँ क्या बोलीं ?",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: "Prose",
      occasion: null,
      notes: null,
      product: null,
      noofpages: "20",
      discountedprice: "90",
      image: "/uploads/241.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 242,
      booktype: "Books for friends and students",
      booknameinenglish: "Baandh lu anant ko",
      booknameinhindi: "बाँध लूँ अनंत को ",
      writer: " Dr. Kaushalya gupta",
      agegroup: "Adults",
      edition: null,
      publisher: "Hem Aunty Publications",
      price: null,
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1ECzH73WjmmHCdl1kVc2a0HY77FUreJBV/view?usp=sharing",
      category: "Books for friends and students",
      description: "Poetry",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: null,
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: null,
      image: "/uploads/242.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 243,
      booktype: "Books for friends and students",
      booknameinenglish: "ya ghar yahi reeti hai",
      booknameinhindi: "या घर याहि रीति है  ",
      writer: " Written by Neeti Shrivastava",
      agegroup: "Adults",
      edition: null,
      publisher: "Hem Aunty Publications",
      price: null,
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/11GIurK8d_wxFsJZDIT5bhMs0_P9TtrAW/view?usp=sharing",
      category: "Books for friends and students",
      description: "Prose",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: null,
      occasion: null,
      notes: null,
      product: null,
      noofpages: null,
      discountedprice: null,
      image: "/uploads/243.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 244,
      booktype: "general books",
      booknameinenglish: "rang rangi rangeeli",
      booknameinhindi: "रंग रँगी रँगीली   ",
      writer: "Madhulika Agarwal",
      agegroup: "2.5-8 years",
      edition: "2024",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1Kv1P_iO9yRBth1JaCZhXYNCIhfPmGA7z/view?usp=sharing",
      category: "hindi, children, 2.5 - 8 years",
      description: " मोहिनी के पास तो रंग नहीं थे, फिर उसने अपनी गुड़िया को कैसे रँगा? इस कहानी से जानते हैं। ",
      illustrator: null,
      typeofproduct: null,
      purpose: null,
      genre: null,
      occasion: null,
      notes: null,
      product: null,
      noofpages: "16",
      discountedprice: "90",
      image: "/uploads/244.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 245,
      booktype: "general books",
      booknameinenglish: "Maine Baate Taare",
      booknameinhindi: "मैंने बाँटे तारे   ",
      writer: "yashveer mohta",
      agegroup: "2.5-8 years",
      edition: "2021",
      publisher: "Hem Aunty Publications",
      price: "100",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1wZCcnQ2A3s0FFIMzlAtSAX9m1HlmZpoF/view?usp=sharing",
      category: "hindi, children, 2.5 - 8 years",
      description: " यशवीर ने तारे किसको बाँटे? आइये जानें।  ",
      illustrator: "yashveer mohta",
      typeofproduct: null,
      purpose: null,
      genre: null,
      occasion: null,
      notes: null,
      product: null,
      noofpages: "12",
      discountedprice: "90",
      image: "/uploads/245.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 246,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Ma (Poster)",
      booknameinhindi: "माँ (पोस्टर)",
      writer: " Hem Bhatnagar",
      agegroup: "Everyone",
      edition: null,
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "Hindi",
      pdflink: "https://drive.google.com/file/d/1vHmNYyuGEwVTkqoEsYo-_gUNF2zvPAUA/view?usp=sharing",
      category: "Special books and products for gifting",
      description: "भारत में केवल एक दिन मातृ दिवस के रूप में नहीं मनाया जा सकता।  क्यों? ",
      illustrator: "Hem Bhatnagar",
      typeofproduct: null,
      purpose: "Gifting",
      genre: null,
      occasion: null,
      notes: null,
      product: null,
      noofpages: "1",
      discountedprice: "135",
      image: "/uploads/246.png",
      isActive: "1",
      isfeatured: "0"
    },
    {
      id: 247,
      booktype: "Special Books and products for gifting",
      booknameinenglish: "Saraswati (Poster)",
      booknameinhindi: "सरस्वती (पोस्टर)",
      writer: " Hem Bhatnagar",
      agegroup: "Everyone",
      edition: null,
      publisher: "Hem Aunty Publications",
      price: "150",
      language: "hindi",
      pdflink: "https://drive.google.com/file/d/158grlCvytwZ2LvJ-zcjjtVe0oWKloIH0/view?usp=sharing",
      category: "Special books and products for gifting",
      description: "सरस्वती की स्तुति स्वरलिपि के साथ।  ",
      illustrator: "Asha Agarwal",
      typeofproduct: null,
      purpose: "Gifting",
      genre: null,
      occasion: null,
      notes: null,
      product: null,
      noofpages: "1",
      discountedprice: "135",
      image: "/uploads/247.png",
      isActive: "1",
      isfeatured: "0"
    }
  ]