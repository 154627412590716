import React from 'react'
import ContactInfo from '../components/ContactInfo'
import ContactForm from '../components/ContactForm'

function ContactPage() {
    return (
        <div>
            <ContactInfo />
            <ContactForm />
        </div>
    )
}

export default ContactPage