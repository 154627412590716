import React, { useEffect, useState } from 'react';
import loadingSvg from '../../assets/bounceLoad.svg';
import config from '../../config/config';
import { format } from 'date-fns';




function TransictionSlip() {
  const { API_BASE_URL } = config;
  const payment_id = sessionStorage.getItem('paymentId');
  const [TransictionDetails, setTransictionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
       // const response = await fetch(`${API_BASE_URL}/Home/Getbooks`, {
        const response = await fetch(`${API_BASE_URL}/api/Order/transiction_slip`, {
          method: 'GET',
          headers: {
            'PaymentId':payment_id,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTransictionDetails(data);

        } else {
          console.error('Error fetching order details');
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
        setError('An error occurred while fetching data.');

      }

      finally {
        setLoading(false);

        console.log("finally block")
    }
    };

    fetchOrderDetails();
  }, [payment_id]);

  

  if (loading) {
    console.log("loading block")
    return <div className="flex justify-center items-center h-screen">
        <img className=' h-auto w-1/2' src={loadingSvg} alt="Loading..." />
    </div>
}

if (error) {
    return <p>{error}</p>;
}

  return (
    <div className="bg-gray-100 flex items-center justify-center h-auto ">
      {TransictionDetails.map((slip) => (
        <div className="bg-white shadow-md rounded-lg w-1/2 mb-10 mt-10" key={slip.paymentId}>
          <div className="bg-[#2563eb] text-white text-center py-10 rounded-t-lg">
            <img
              src="static/media/logo_3.53b1908a961faece3339.png"
              alt="Razorpay"
              className="mx-auto mb-2 w-60 h-20"
            />
            {/* <h1 className="text-xl font-bold">AcmeHeal</h1> */}
          </div>
          <div className="p-6 mb-4">
            <div className="text-center mb-4">
              <div className="text-3xl font-semibold text-gray-800">
                ₹{slip.amount}
              </div>
              <div className="text-green-500 font-medium">Paid Successfully</div>
            </div>
            <div className="border-t-4 border-[#2563eb] pt-4 pl-12 pr-12 p-2">
              <div className="mb-2 flex flex-row justify-between text-xl ">
                <span className="font-medium text-gray-400">Payment Id:</span>
                <span className="text-gray-800">{slip.paymentId}</span>
              </div>
              <div className="mb-2 flex flex-row justify-between text-xl">
                <span className="font-medium text-gray-400">Track order Id:</span>
                <span className="text-gray-800">{slip.hemorderId}</span>
              </div>
              <div className="mb-2 flex flex-row justify-between text-xl ">
                <span className="font-medium text-gray-400">Paid On:</span>
                <span className="text-gray-800">{format(new Date(slip.date), 'eeee, MMMM do, yyyy h:mm:ss a')}</span>
              </div>
              <div className="mb-2 flex flex-row justify-between text-xl">
                <span className="font-medium text-gray-400">Method:</span>
                <span className="text-gray-800">{slip.paymentMode}</span>
              </div>
              <div className="mb-2 flex flex-row justify-between text-xl">
                <span className="font-medium text-gray-400">Mobile Number:</span>
                <span className="text-gray-800">{slip.mobile}</span>
              </div>
            </div>
            <div className="border-t border-gray-200 mt-4 pt-4 pl-12 pr-12 p-2">
              <div className="mb-2 flex flex-row justify-between text-xl">
                <span className="font-medium text-gray-400">Name:</span>
                <span className="text-gray-800">{slip.name}</span>
              </div>
              <div className="mb-2 flex flex-row justify-between text-xl">
                <span className="font-medium text-gray-400">Email:</span>
                <a href={`mailto:${slip.email}`} className="text-blue-500">
                  {slip.email}
                </a>
              </div>
              {/* <div className="mb-2 flex flex-row justify-between text-xl">
                <span className="font-medium text-gray-400">Phone:</span>
                <span className="text-gray-800">{slip.mobile}</span>
              </div> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TransictionSlip;
