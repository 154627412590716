import React from 'react'
import Hindi from '../components/Hindi'
import HindiHero from '../components/HindiHero'

function HindiPage() {
    return (
        <div>
            <HindiHero />
            <Hindi />
        </div>
    )
}

export default HindiPage