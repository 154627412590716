import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import payment from "../../assets/images/scannerfile.jpg"
import RenderRazorpay from '../../common/RenderRazorPay'
import ReCAPTCHAComponent from '../../common/googleCaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, selectCartData, selectTotalBookPrice } from '../../ReduxSlice/BookSlice';
import { createRazorpayPaymentLink } from '../../common/RazorPayPartialPayment';

const Form = ({ paymentMode }) => {
  // console.log("paymentMode",paymentMode)
  const { API_BASE_URL, RAZOR_PAY_API_KEY, RAZOR_PAY_API_SECRET } = config;
  const cartItems = useSelector(selectCartData)
  const total = useSelector(selectTotalBookPrice)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [error, setErrors] = useState({mobile :''});



  const location = useLocation();

  //const cartItems = location.state ? location.state.cartItems : [];
  // const total = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  console.log("checktotal", total, cartItems)
  const deliveryCharge = location.state ? location.state.delivery : 0;

  const [displayRazorpay, setDisplayRazorpay] = useState(false);

  const [ordeid, setorderID] = useState("");
  const [preFillFormData, setPreFillFormData] = useState({
    name: '',
    mobile: '',
    email: ''
  });
  const [hemOrderID, setHemOrderId] = useState(null);



  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    pinCode: '',
    address: '',
    city: '',
    state: '',
  });
  // const [form ,setform]=useState(formData)
  // const formDatas = formData;
  // console.log("formdata in array",formDatas);

  const [paymentProofImage, setPaymentProofImage] = useState(null);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false); // Captcha state
  const [loading, setLoading] = useState(false)

  const handleRecaptcha = (value) => {
    setIsCaptchaValid(!!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPreFillFormData({
      name: formData.name,
      mobile: formData.mobile,
      email: formData.email
    });
    setLoading(true);
    setIsCaptchaValid(false);

    //  const storedCart = localStorage.getItem('cart');
    // const cartItems = storedCart ? JSON.parse(storedCart) : [];

    const checkoutPayload = {
      total,
      deliveryCharges: deliveryCharge,
      orderDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      paymentProof: paymentProofImage,
      ...formData,
      _lstUM_OrderItem: cartItems
        .filter(item => item.quantity > 0)
        .map(item => ({
          bookId: item.id,
          bookName: item.name,
          bookQuantity: item.quantity,
          perUnitBookPrice: item.price,
          totalBookPrice: item.price * item.quantity,
          addDate: new Date().toISOString(),
          updateDate: new Date().toISOString(),
        })),
      _lstUM_OrderStatus: [
        {
          id: 0,
          orderId: 0,
          statusID: 0,
          statusName: 'string',
          updateDate: new Date().toISOString(),
          updatedBy: 0,
          isActive: 0,
        },
      ],
    };

    try {
      const response = await fetch(`${API_BASE_URL}/Home/Checkoutorder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(checkoutPayload),
      });

      if (response.ok) {
        const data = await response.text();
        const RazorpayorderIds = JSON.parse(data).gatewayOrderID
        console.log("data", JSON.parse(data).gatewayOrderID);
        const hemOrderId = JSON.parse(data).orderId;
        setHemOrderId(hemOrderId)
        setDisplayRazorpay(true);
        setorderID(RazorpayorderIds);
        setLoading(false);
        setIsCaptchaValid(false);

        if (paymentMode == 'cashPay') {
          const newOrderDetails = {
            orderId: RazorpayorderIds,
            paymentId: "cashpaymentId",
            signature: "7a68edb84c919dd6c0bb48be543903f71f30e2864c4ccd73fc0d28113a54aa04",
            Status: "succeeded",
            Currency: "INR",
            Amount: total,
            PaymentMode: "cashPay"
          };
         


          try {
            const res = await fetch(`${API_BASE_URL}/api/Order/Capture-Payment`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(newOrderDetails,)
            });
            if (res.ok) {
               navigate('/transactionslip')

            }

            else if (!res.ok) {
              throw new Error('Failed to save payment response');
            }

            // alert(`Payment ${status}`);

          } catch (error) {
            console.error('Error:', error);
          }
        }

        dispatch(clearCart())
       


        toast.success(
          <div className='z-99'>
            <p>Please save the order for tracking and future reference.</p>
            <hr />
            <p className='font-bold'> Order Id : &nbsp; {hemOrderId}</p>
            <button className='mt-1 bg-red-500 rounded-md text-white text-xs px-2 py-1.5' onClick={() => toast.dismiss()}>Close</button>
          </div>,
          { autoClose: false }
        );
        if(paymentMode == "paybyMail"){
          var PartialRazorPayLink= createRazorpayPaymentLink(ordeid, total,formData,RAZOR_PAY_API_KEY, RAZOR_PAY_API_SECRET);
        }

        localStorage.removeItem('cart');

        setFormData({
          name: '',
          mobile: '',
          email: '',
          pinCode: '',
          address: '',
          city: '',
          state: '',
        });

        setPaymentProofImage(null);

        

        // Send email notification
        console.log("PartialRazorPayLink",PartialRazorPayLink);
        const emailFormData = new FormData();
        emailFormData.append('ToEmail', 'sales@mail.edumitram.co.in');
        emailFormData.append('Subject', 'New Order Notification');
        const emailBodyHTML = `
                <p style="font-weight: bold;">A new order has been placed with the following details:</p>
                <br>
                <p><span style="font-weight: bold;">Tracking Order ID:</span> ${JSON.parse(data).orderId}</p>
                <p><span style="font-weight: bold;">Total Price:</span> Rs. ${total}</p>
                <p><span style="font-weight: bold;">Name:</span> ${formData.name}</p>
                <p><span style="font-weight: bold;">Mobile:</span> ${formData.mobile}</p>
                <p><span style="font-weight: bold;">Email:</span> ${formData.email}</p>
                <p><span style="font-weight: bold;">Pincode:</span> ${formData.pinCode}</p>
                <p><span style="font-weight: bold;">Address:</span> ${formData.address}</p>
                <p><span style="font-weight: bold;">City:</span> ${formData.city}</p>
                <p><span style="font-weight: bold;">State:</span> ${formData.state}</p>
                <br>
                <p>Pay by this Link</p>
                <a href=${PartialRazorPayLink}> ${PartialRazorPayLink} </a>
                `;

        console.log("emailFormData", emailFormData)


        emailFormData.append('Body', emailBodyHTML);

        emailFormData.append('EmailCode', '1');
        emailFormData.append('Body', `A new order has been placed with the following details:\n\nOrder ID: ${data}\nTotal Price: Rs. ${total}\nName: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}\nPincode: ${formData.pinCode}\nAddress: ${formData.address}\nCity: ${formData.city}\nState: ${formData.state}`);

        const emailResponse = await fetch('https://edmcorpservices.edumitram.co.in/api/Email/SendHemPubBeta', {
          method: 'POST',
          body: emailFormData,
        });

        if (!emailResponse.ok) {
          throw new Error('Failed to send email notification');
        }
        setLoading(false);

      } else {
        toast.error('Error while saving the order. Please try again.');
        setLoading(false);
        setIsCaptchaValid(false)
      }
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.');
      setLoading(false);
      setIsCaptchaValid(false)
    }
  };

  const handleChange = (e) => {
    const {name ,value} = e.target;
    console.log("name",name,value);
      // Restrict mobile input to 10 digits
      // if (name === "mobile" && value.length > 10) {
      //   return; // Do nothing if the input exceeds 10 digits
      // }
    setFormData({...formData,[name]:value});


    const phoneRegex = /^[6-9]\d{9}$/;
    if(name === "mobile"){
      console.log("phoneRegex.test(value)",phoneRegex.test(value))
      
        if(value === "" || phoneRegex.test(value)){
          setErrors((prevErrors)=>({
            ...prevErrors,mobile:""
          }));
        }else{
          setErrors((prevErrors)=>({
            ...prevErrors,mobile:"Invalid phone number"
          }))
        }
      
    }else{
      setFormData({ ...formData, [name]: value });


    }
  };

  const handleImageChange = (e) => {

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPaymentProofImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (

    <section className=" h-[55vh] md:h-auto overflow-y-auto relative max-w-7xl mx-auto px-5">
      <div className="container z-10">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="  relative rounded-lg shadow-lg">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="text-gray-700 font-medium">
                      Name
                      <input
                        type="text"
                        placeholder="Mention your name"
                        className="w-full rounded py-1.5 px-[14px] placeholder:text-sm text-body-color text-base border border-gray-300 outline-none focus:border-primary"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>

                  <div>
                    <label className="text-gray-700 font-medium">
                      Mobile
                      <input
                        placeholder="+91 75*******"
                        className="w-full rounded py-1.5 px-[14px] placeholder:text-sm text-body-color text-base border border-gray-300 outline-none focus:border-primary"
                        type="text"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        maxLength={10}
                        required
                      />
                      {error.mobile && <p className='text-red-500'>{error.mobile}</p>}
                    </label>
                  </div>

                  <div>
                    <label className="text-gray-700 font-medium">
                      Email
                      <input
                        placeholder="name@gmail.com"
                        className="w-full rounded py-1.5 px-[14px] placeholder:text-sm text-body-color text-base border border-gray-300 outline-none focus:border-primary"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>

                  <div>
                    <label className="text-gray-700 font-medium">
                      Pincode
                      <input
                        placeholder="Mention your Pincode"
                        className="w-full rounded py-1.5 px-[14px] placeholder:text-sm text-body-color text-base border border-gray-300 outline-none focus:border-primary"
                        type="text"
                        name="pinCode"
                        value={formData.pinCode}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>

                  <div className="md:col-span-2">
                    <label className="text-gray-700 font-medium">
                      Address
                      <input
                        placeholder="Write your address with Street name"
                        className="w-full rounded py-1.5 px-[14px] placeholder:text-sm text-body-color text-base border border-gray-300 outline-none focus:border-primary"
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>

                  <div>
                    <label className="text-gray-700 font-medium">
                      City
                      <input
                        placeholder="Mention your City"
                        className="w-full rounded py-1.5 px-[14px] placeholder:text-sm text-body-color text-base border border-gray-300 outline-none focus:border-primary"
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>

                  <div>
                    <label className="text-gray-700 font-medium">
                      State
                      <input
                        placeholder="Mention your State"
                        className="w-full rounded py-1.5 px-[14px] placeholder:text-sm text-body-color text-base border border-gray-300 outline-none focus:border-primary"
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>
                </div>

                {cartItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <input type="hidden" name={`cartItems[${index}].bookId`} value={item.bookId} />
                    <input type="hidden" name={`cartItems[${index}].bookName`} value={item.bookName} />
                    <input type="hidden" name={`cartItems[${index}].bookQuantity`} value={item.bookQuantity} />
                    <input type="hidden" name={`cartItems[${index}].perUnitBookPrice`} value={item.perUnitBookPrice} />
                    <input type="hidden" name={`cartItems[${index}].totalBookPrice`} value={item.totalBookPrice} />
                    <input type="hidden" name={`cartItems[${index}].addDate`} value={item.addDate} />
                    <input type="hidden" name={`cartItems[${index}].updateDate`} value={item.updateDate} />
                  </React.Fragment>
                ))}

                <div className="mt-2">
                  <ReCAPTCHAComponent onChange={handleRecaptcha} />
                </div>

                <div className="mt-2">
                  <button
                    type="submit"
                    disabled={!isCaptchaValid}
                    className={`w-full bg-blue-600 rounded border border-primary p-3 transition ${!isCaptchaValid ? "cursor-not-allowed opacity-50" : ""
                      } hover:bg-blue-700 text-white`}
                  >
                    {!loading ? "Place Order" : "Placing Order..."}
                  </button>
                </div>

                {displayRazorpay && paymentMode === 'online' && (
                  <RenderRazorpay
                    amount={total}
                    currency="INR"
                    orderId={ordeid}
                    keyId={RAZOR_PAY_API_KEY}
                    keySecret={RAZOR_PAY_API_SECRET}
                    preFillFormData={preFillFormData}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>

  );
};

export default Form;
