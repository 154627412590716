import React, { useState } from 'react';
import config from '../config/config';

function TrackOrder() {
    const { API_BASE_URL } = config;
    const [orderData, setOrderData] = useState(null);
    const [orderId, setOrderId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchOrderData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/Home/trackorder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    orderId: orderId
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setOrderData(data);
            setError(null);
        } catch (error) {
            setError('Error fetching order data');
            console.error('Error fetching order data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTrackOrder = () => {
        if (orderId.trim() !== '') {
            fetchOrderData();
        } else {
            setError('Please enter an order ID');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };


    return (
        <div className='mt-10 max-w-4xl mx-auto h-screen'>

            <h2 className='py-4 font-semibold'>Write your Tracking ID here to get details about your order shipment!</h2>

            <div>
                <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">

                    <input type="text"
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                        placeholder="Enter Order ID" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" required />
                    <button onClick={handleTrackOrder} type="button" class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ">Search</button>
                </div>
            </div>


            {isLoading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {orderData && (
                <div className='md:flex md:flex-col flex-none gap-4 p-2 bg-gray-100 rounded-md mt-4'>
                    <p>Order ID: {orderData[0].orderId}</p>
                    <p>Total: {orderData[0].total}</p>
                    <p>Order Date: {formatDate(orderData[0].orderDate)}</p>

                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 bg-white ">
                        <thead class="text-xs text-gray-700 uppercase ">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Update Date
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderData[0]._lstUM_OrderStatus.map((status, index) => (
                                <tr class="" key={index}>
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                        {status.statusName}
                                    </th>
                                    <td class="px-6 py-4">
                                        {formatDate(status.updateDate)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                    <table className="w-full text-sm text-left text-gray-500 bg-white">
                        <thead className="text-xs text-gray-700 uppercase ">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Book Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Order Id
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Book Quantity
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Per Unit Price
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderData[0]._lstUM_OrderItem.map((book, index) => (
                                <tr className="" key={index}>
                                    <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                        {book.bookName}
                                    </td>
                                    <td className="px-6 py-2">
                                        {book.orderId}
                                    </td>
                                    <td className="px-6 py-2">
                                        {book.bookQuantity}
                                    </td>
                                    <td className="px-6 py-2">
                                        {book.perUnitBookPrice}
                                    </td>
                                    <td className="px-6 py-2">
                                        {book.totalBookPrice}
                                    </td>
                                </tr>
                                
                            ))}
                            <tr>
                            <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                        Total Delivery Charges
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="px-6 py-2">
                                    {orderData[0].deliveryCharges}
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="w-full text-sm text-left text-gray-500 bg-white">
                        <thead className="text-xs text-gray-700 uppercase ">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Courier Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Tracking Number
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderData[0]._lstUM_OrderItem.map((courier, index) => (
                                <tr className="" key={index}>
                                    <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                        {courier.courierName}
                                    </td>
                                    <td className="px-6 py-2">
                                        {courier.courierId}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default TrackOrder;
