import React from 'react';
import slider2 from "../assets/images/cover_webpage1.png";


function HindiHero() {
    return (
        <div>        <div>
            <div style={{ position: "relative" }}>
                <img
                    className="md:h-[350px] lg:h-[350px] xl:h-[350px] h-[200px] brightness-90 "
                    src={slider2}
                    alt="book-banner-1"
                    style={{
                        width: "100%",
                        objectFit: "cover",
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                        className="max-w-7xl mx-auto md:space-y-8 lg:space-y-8 xl:space-y-8 space-y-6 md:py-24 lg:py-24 xl:py-24 py-4 md:px-0 lg:px-0 xl:px-0 px-4 flex items-center"
                    >

                    </div>
                </div>
            </div>
            {/* <BannerNav links={links} /> */}
        </div></div>
    )
}

export default HindiHero;