/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Login from '../components/Login'

function LoginPage() {

    const [loginStatus, setLoginStatus] = useState(false);

    return (
        <div>
            <Login setLoginStatus={setLoginStatus} />
        </div>
    )
}

export default LoginPage